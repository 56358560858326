import React, { useEffect, useState } from 'react'
import SideNav from '../SideNav/SideNav'
import Header from '../Header/Header'
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardDetail } from '../../Redux/Slices/Login/login';


const initialState = {
    fromDate: "",
    toDate: "",
    timeFrame: "",
    error: "",

}

const Dashboard = ({ checkAuth }) => {


    console.log(checkAuth(), "here")
    const location = useLocation();
    const dispatch = useDispatch();
    const { getDashboardDetail } = useSelector(state => state.accountMgmt);
    const [iState, updateState] = useState(initialState);
    const { fromDate, toDate, timeFrame, error } = iState;
    const heading = location?.state;



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "timeFrame") {
            updateState({
                ...iState,
                [name]: value,
                fromDate: "",
                toDate: "",
                error: "",
            });
        } else if (name == "fromDate" || name == "toDate") {
            updateState({ ...iState, [name]: value, timeFrame: "", error: "" });
        }
    };


    const handleRefresh = () => {
        updateState(initialState);
        dispatch(DashboardDetail());
    };

    const handleFilterApply = () => {
        if (fromDate == "" && toDate !== "") {
            updateState({ ...iState, error: "*Please select From Date" });
        } else if (fromDate !== "" && toDate == "") {
            updateState({ ...iState, error: "*Please select To Date" });
        } else if (fromDate == "" && toDate == "") {
            updateState({ ...iState, error: "*Please select atleast one filter" });
        } else {
            const data = {
                startDate: fromDate,
                endDate: toDate,
                timeFrame,
            };
            dispatch(DashboardDetail(data))
                .then((res) => {
                    console.log("res", res);
                })
                .catch((err) => {
                    console.log("err", err);
                });
        }
    };


    useEffect(() => {
        const data = {
            startDate: fromDate,
            endDate: toDate,
            timeFrame,
          };
        dispatch(DashboardDetail(data));
    }, [timeFrame])

    console.log({ getDashboardDetail })
    return (
        <div>
            <SideNav />
            <Header heading={heading} />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="Small-Wrapper">
                        {/* <div class="TitleBox">
              <h4 class="Title">Welcome to your dashboard</h4>
          </div> */}
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        name="fromDate"
                                        value={fromDate}
                                        onChange={handleInputChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        name="toDate"
                                        value={toDate}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        min={fromDate}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilterApply}>
                                        Apply
                                    </button>

                                    <button
                                        className="Button Cancel"
                                        onClick={handleRefresh}
                                        style={{ marginLeft: "6px" }}
                                    >
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select
                                        className="form-control"
                                        name="timeFrame"
                                        onClick={handleInputChange}
                                    >
                                        <option value="" selected={timeFrame == ""}>
                                            Select{" "}
                                        </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span className="error">{error}</span>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Users</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Registered Users</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalRegisteredUsers}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Registered Astrologers</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalRegisteredAstrologers}</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Appointments</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalAppointments}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-database" />
                                </span>
                                <h2>Total Upcoming Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalUpcomingAppointments}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Completed Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalCompletedAppointments}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-cube" />
                                </span>
                                <h2>Total Cancelled Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalCanceledAppointments
                                    }</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-users" />
                                </span>
                                <h2>Total Rescheduled Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalRescheduleAppointments
                                    }</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total No Show Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalNoshowAppointments}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Voice Calls (offline)</h2>
                                <article>
                                    <h3>--</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Audio/Video Calls (online)</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalVideoCall}</h3>
                                </article>
                            </div>
                        </div>
                        {/* <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-user" />
                                </span>
                                <h2>Total Video Calls (online)</h2>
                                <article>
                                    <h3>51</h3>
                                </article>
                            </div>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Total Revenue</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Revenue Generated form Appointments</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalRevenue}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-database" />
                                </span>
                                <h2>Total Amount Paid to Astrologers</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalAmountToAstrologer}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Commission Earned</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalCommisionEarned}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Tax</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalTax}</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="TitleBox">
                                <h4 className="Title">Support Enquiry</h4>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-car" />
                                </span>
                                <h2>Total Support Enquiry</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalSupportEnquiry}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Support Enquiry Pending</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalSupportEnquiryPending}</h3>
                                </article>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="DashboardOverview">
                                <span>
                                    <i className="fa fa-usd" />
                                </span>
                                <h2>Total Support Enquiry Resolved</h2>
                                <article>
                                    <h3>{getDashboardDetail?.data?.totalSupportEnquiryResolved}</h3>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard