import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { loginApiSlice, verifyOtp } from '../../Redux/Slices/Login/login'
import { commomObj } from '../../Utils/util'
import { toast } from 'react-toastify'

const LoginOtp = () => {
    const [otp, setOtp] = useState('')
    const [stateOtp, setStateOtp] = useState('')
    const { state } = useLocation()
    console.log('state in login opt', state)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    console.log('state', state)
    useEffect(() => {
        setStateOtp(state?.otp)
    }, [])
    const handleVerifyOtp = () => {
        const data = {
            emailOrMobile: state?.contact,
            otp: Number(otp)
        }
        dispatch(verifyOtp(data)).then((res) => {
            console.log('Otp verify result', res)
            if (res?.payload?.status === 200) {
                window.localStorage.setItem('AstroTechAdmin', res?.payload?.data?.result?.jwtToken);
                window.localStorage.setItem('AstroTechAdminDetail', JSON.stringify(res?.payload?.data?.result));

                navigate('/dashboard', { state: "Dashboard" })
                toast.success("Login Successfully", commomObj);
            }
        })
    }
    const handleResend = () => {
        const data = {
            emailOrMobile: state?.contact
        }
        dispatch(loginApiSlice(data)).then((res) => {
            console.log('login Response', res)
            if (res?.payload?.status === 200) {
                // navigate('/loginOtp', { state: res?.payload?.data });
                // updateState(initialState)
                setStateOtp(res?.payload?.data?.otp)
            }
        })
    }
    return (
        <div>
            <div className="LoginArea">
                <div className="LoginBox">
                    <figure>
                        <img src={require('../../Assets/images/Logo.png')} />
                    </figure>
                    <form>
                        <h3>OTP Verification</h3>
                        <p>
                            Please enter 4 digit OTP Verification code received on your email
                            address &amp; mobile no.
                        </p>
                        <div className="form-group">
                            <div className="OTPBox">
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span> </span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>

                            <p className="text-right">
                                <a onClick={handleResend}>Resend:{"   "}</a>
                                {/* <br /> */}
                                <span>{stateOtp}</span>
                            </p>
                        </div>
                        <a className="Button" onClick={handleVerifyOtp}>
                            Verify OTP
                        </a>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default LoginOtp