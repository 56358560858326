import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { useDispatch, useSelector } from 'react-redux'
import { staticAbout, staticAstrologer, staticContact, staticCopyright, staticGuildelines, staticTerms, staticZodiac } from '../../Redux/Slices/staticMgmt'
import { Link, useLocation } from 'react-router-dom'

const initialState = {
    type: '',

}

const StaticContent = () => {

    const [iState, updateState] = useState(initialState);
    const { type } = iState;
    const { getCopyRight, getAstrologer, getAbout, getTerms, getContact, getGuildelines, getZoidac } = useSelector((state) => state.staticMgmt);
    const dispatch = useDispatch();
    const location = useLocation();
    const heading = location?.state;


    useEffect(() => {

        dispatch(staticAstrologer());
        dispatch(staticAbout());
        dispatch(staticContact());
        dispatch(staticTerms());
        dispatch(staticCopyright());
        dispatch(staticGuildelines());
        dispatch(staticZodiac());



    }, [])

    console.log({ getCopyRight });


    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                    </div>
                    <div className="StaticArea">
                        <div id="accordion">
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse1"
                                    aria-expanded="true"
                                >
                                    <h4>Why Astrology</h4>
                                    <Link to="/content-management-edit" state={getAstrologer?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse1" className="panel-collapse collapse in">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getAstrologer?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getAstrologer?.data?.at(0)?.description }} />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse2"
                                    aria-expanded="true"
                                >
                                    <h4>About Us</h4>
                                    <Link to="/content-management-edit" state={getAbout?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse2" className="panel-collapse collapse">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getAbout?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getAbout?.data?.at(0)?.description }} />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse3"
                                >
                                    <h4>Contact Us</h4>
                                    <Link to="/content-management-edit" state={getContact?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse3" className="panel-collapse collapse">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getContact?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getContact?.data?.at(0)?.description }} />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse5"

                                >
                                    <h4>Terms &amp; Conditions</h4>
                                    <Link to="/content-management-edit" state={getTerms?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse5" className="panel-collapse collapse">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getTerms?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getTerms?.data?.at(0)?.description }} />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse6"
                                >
                                    <h4>Privacy Policy</h4>
                                    <Link to="/content-management-edit" state={getCopyRight?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse6" className="panel-collapse collapse">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getCopyRight?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getCopyRight?.data?.at(0)?.description }} />

                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse8"
                                >
                                    <h4>Guidelines (Astrologer)</h4>
                                    <Link to="/content-management-edit" state={getGuildelines?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse8" className="panel-collapse collapse">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getGuildelines?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getGuildelines?.data?.at(0)?.description }} />
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div
                                    className="card-header"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapse9"
                                    onClick={() => updateState({ ...iState, type: "ZODIAC_SIGN" })}

                                >
                                    <h4>Zodiac Sign</h4>
                                    <Link to="/content-management-edit" state={getZoidac?.data?.at(0)}>
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </div>
                                <div id="collapse9" className="panel-collapse collapse">
                                    <div className="card-body">
                                        <p dangerouslySetInnerHTML={{ __html: getZoidac?.data?.at(0)?.description1 }} />
                                        <p dangerouslySetInnerHTML={{ __html: getZoidac?.data?.at(0)?.description }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default StaticContent
