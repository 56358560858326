import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= User lsit===========================//
export const userList = createAsyncThunk(
    "userMgmt/userList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`user/user-list?page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);


export const userBasicDetail = createAsyncThunk(
    "userMgmt/userBasicDetail",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`user/basic-details?userId=${payload?.id ? payload?.id : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const userAppointmentHistory = createAsyncThunk(
    "userMgmt/userAppointmentHistory",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`user/appointment-history?userId=${payload?.id ? payload?.id : ''}&bookingStatus=${payload?.bookingStatus?payload?.bookingStatus:''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);



export const statusChange = createAsyncThunk(
    "userMgmt/statusChange",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.post(`${payload?.type}`, payload?.data, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);



const initialState = {
    getUserList: '',
    getUserBasicDetail: '',
    getUserAppointment:'',
    loader: ''
}

const userMgmtSlice = createSlice({
    name: 'users',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(userList.pending, (state) => {
            state.loader = true;
            state.getUserList = {}
        })
        builder.addCase(userList.fulfilled, (state, action) => {
            state.loader = false;
            state.getUserList = action.payload;
        })
        builder.addCase(userList.rejected, (state, action) => {
            state.loader = false;
            state.getUserList = action.error.message;
        });
        builder.addCase(userBasicDetail.pending, (state) => {
            state.loader = true;
            state.getUserBasicDetail = {}
        })
        builder.addCase(userBasicDetail.fulfilled, (state, action) => {
            state.loader = false;
            state.getUserBasicDetail = action.payload;
        })
        builder.addCase(userBasicDetail.rejected, (state, action) => {
            state.loader = false;
            state.getUserBasicDetail = action.error.message;
        });
        builder.addCase(userAppointmentHistory.pending, (state) => {
            state.loader = true;
            state.getUserAppointment = {}
        })
        builder.addCase(userAppointmentHistory.fulfilled, (state, action) => {
            state.loader = false;
            state.getUserAppointment = action.payload;
        })
        builder.addCase(userAppointmentHistory.rejected, (state, action) => {
            state.loader = false;
            state.getUserAppointment = action.error.message;
        });

    }

})


export default userMgmtSlice.reducer;



