import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { transactionList } from "../../Redux/Slices/paymentMgmt";
import { toast } from "react-toastify";
import { commomObj } from "../../Utils/util";
import { BeatLoader } from "react-spinners";
import moment from "moment";
import Pagination from "react-js-pagination";

const initialState = {
  search: "",
  fromDate: "",
  toDate: "",
  timeFrame: "",
  error: "",
};

const TransactionDetail = () => {
  const location = useLocation();
  const heading = location?.state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getTransactionList, loader } = useSelector(
    (state) => state.paymentMgmt
  );
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  const [iState, updateState] = useState(initialState);
  const { search, fromDate, toDate, error, timeFrame } = iState;

  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateActivePage(pageNumber);
    updateSerialNo(ser);
    const data = {
      page: pageNumber,
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
    };
    dispatch(transactionList(data));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "timeFrame") {
      updateState({
        ...iState,
        [name]: value,
        fromDate: "",
        toDate: "",
        error: "",
      });
    } else if (name == "fromDate" || name == "toDate") {
      updateState({ ...iState, [name]: value, timeFrame: "", error: "" });
    } else {
      updateState({ ...iState, [name]: value, error: "" });
    }
  };

  const handleRefresh = () => {
    updateState(initialState);
    updateSerialNo(10);
    dispatch(transactionList());
  };

  const handleFilterApply = () => {
    if (fromDate == "" && toDate !== "") {
      updateState({ ...iState, error: "*Please select From Date" });
    } else if (fromDate !== "" && toDate == "") {
      updateState({ ...iState, error: "*Please select To Date" });
    } else if (fromDate == "" && toDate == "" && search?.trim() == "") {
      updateState({ ...iState, error: "*Please select atleast one filter" });
    } else {
      const data = {
        search,
        startDate: fromDate,
        endDate: toDate,
        timeFrame,
      };
      dispatch(transactionList(data))
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    const data = {
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
    };
    dispatch(transactionList(data))
      .then((res) => {
        console.log("res", res);
        if (res?.payload?.status == 401) {
          toast.error("Invalid Token.Please Login again", commomObj);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [timeFrame]);

  console.log({ getTransactionList });

  return (
    <>
      <Header heading={heading} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title"></h4>
            <a className="TitleLink" href="javascript:void(0);">
              <i className="fa fa-download" /> Download Excel
            </a>
          </div>
          <div className="Small-Wrapper">
            <div className="FilterArea">
              <div className="FilterLeft">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here.."
                    name="search"
                    value={search}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="toDate"
                    value={toDate}
                    onChange={handleInputChange}
                    className="form-control"
                    min={fromDate}
                  />
                </div>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <button className="Button" onClick={handleFilterApply}>
                    Apply
                  </button>

                  <button
                    className="Button Cancel"
                    onClick={handleRefresh}
                    style={{ marginLeft: "6px" }}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
              <div className="FilterRight">
                <div className="form-group">
                  <label>Timeframe</label>
                  <select
                    className="form-control"
                    name="timeFrame"
                    onClick={handleInputChange}
                  >
                    <option value="" selected={timeFrame == ""}>
                      Select{" "}
                    </option>
                    <option value="Today">Today</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                  </select>
                </div>
              </div>
            </div>
            <span className="error">{error}</span>
          </div>
          <div className="TableList">
            <table style={{ width: "110%" }}>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Transaction ID</th>
                  <th>Transaction Date &amp; Time</th>
                  <th>User ID</th>
                  <th>User Name</th>
                  <th>Amount (in Rs.)</th>
                  <th>Payment Status</th>
                  <th>Download Invoice</th>
                </tr>
              </thead>
              <tbody>
                {getTransactionList?.data?.result?.length > 0 ? (
                  getTransactionList?.data?.result?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {" "}
                          {(i + 1 + serialNo - 10).toString().padStart(3, "0")}
                        </td>
                        <td>--</td>
                        <td>
                          {moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")}
                        </td>
                        <td>{item?.userData?.userNo}</td>
                        <td>{item?.userData?.name}</td>
                        <td>{item?.transactionData?.amount / 100}</td>
                        <td>
                          <span className="Green">
                            {item?.transactionData?.status == "success"
                              ? "Successful"
                              : ""}
                          </span>
                        </td>
                        <td>
                          <a href="javascript:void(0);" className="Blue">
                            {" "}
                            Download{" "}
                          </a>
                        </td>
                      </tr>
                    );
                  })
                ) : !loader ? (
                  <tr>
                    <td colSpan="10">
                      <p className="center">No Data found.</p>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            {loader ? (
              <p className="load">
                <BeatLoader loading={loader} size={10} color="#fd701e" />
              </p>
            ) : (
              ""
            )}
          </div>
          <br />
          <div className="pagination">
            <ul>
              {getTransactionList?.data?.total > 0 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={getTransactionList?.data?.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionDetail;
