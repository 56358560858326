import React, { useEffect } from "react";
import "./App.css"

import {
  Route,
  HashRouter,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Components/Login Flow/Login";
import LoginOtp from "./Components/Login Flow/LoginOtp";
import Dashboard from "./Components/DashBoard/Dashboard";
import UserManagement from "./Components/User Management/UserManagement";
import UserDetail from "./Components/User Management/UserDetail";
import StaticContent from "./Components/StaticContent/StaticContent";
import StaticContentEdit from "./Components/StaticContent/StaticContentEdit";
import BlogsMgmt from "./Components/BlogsManagement/BlogsMgmt";
import AddBlogs from "./Components/BlogsManagement/AddBlogs";
import ServiceMgmt from "./Components/ServiceManagement/ServiceMgmt";
import AddService from "./Components/ServiceManagement/AddService";
import Category from "./Components/BlogsManagement/Category";
import BannerMgmt from "./Components/BannerManagement/BannerMgmt";
import SupportMgmt from "./Components/SupportManagement/SupportMgmt";
import ApprovedAstrologer from "./Components/AstrologerManagement/ApprovedAstrologer";
import AppointmentMgmt from "./Components/AppointmentManagement/AppointmentMgmt";
import NewRegister from "./Components/AstrologerManagement/NewRegister";
import RevenueDetail from "./Components/PaymentManagement/RevenueDetail";
import TransactionDetail from "./Components/PaymentManagement/TransactionDetail";
import SubAdminMgmt from "./Components/SubAdminManagement/SubAdminMgmt";
import NotificationMgmt from "./Components/NotificationManagement/NotificationMgmt";
import MyAccount from "./Components/Account/MyAccount";
import AddCategory from "./Components/NotificationManagement/AddCategory";
import NotificationThread from "./Components/NotificationManagement/NotificationThread";
import AddSubAdmin from "./Components/SubAdminManagement/AddSubAdmin";
import SubAdminDetail from "./Components/SubAdminManagement/SubAdminDetail";
import NewRegisterDetail from "./Components/AstrologerManagement/NewRegisterDetail";
import ServiceDetail from "./Components/ServiceManagement/ServiceDetail";
import ViewDetails from "./Components/SupportManagement/ViewDetails";
import ApprovedDetail from "./Components/AstrologerManagement/ApprovedDetail";
import FaqMgmt from "./Components/FaqManagement/FaqMgmt";
import BlogDetails from "./Components/BlogsManagement/BlogDetails";
import UpcomingDetail from "./Components/AppointmentManagement/UpcomingDetail";
import RescheduleDetail from "./Components/AppointmentManagement/RescheduleDetail";
import CompletedDetail from "./Components/AppointmentManagement/CompletedDetail";
import NoshowDetail from "./Components/AppointmentManagement/NoshowDetail";
import AppointmentDetails from "./Components/User Management/AppointmentDetails";
import { isLoggedIn } from "./Utils/util";



function PrivateRoute({ component: Component, userType, ...rest }) {
  const isLogged = isLoggedIn("AstroTechAdmin");
  return isLogged ? <Component checkAuth={() => isLogged} {...rest} /> : <Navigate to="/" />;
}

function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/loginOtp" element={<LoginOtp />} />

          <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
          <Route path="/userManagement" element={<PrivateRoute component={UserManagement} />} />
          <Route path="/userManagement/appointment-history-details" element={<PrivateRoute component={AppointmentDetails} />} />

          <Route path="/userDetail/:type" element={<PrivateRoute component={UserDetail} />} />

          <Route path="/content-management" element={<PrivateRoute component={StaticContent} />} />
          <Route path="/content-management-edit" element={<PrivateRoute component={StaticContentEdit} />} />
          <Route path="/faq-management/:pathType" element={<PrivateRoute component={FaqMgmt} />} />



          <Route path="/service-management" element={<PrivateRoute component={ServiceMgmt} />} />
          <Route path="/service-management/add-service" element={<PrivateRoute component={AddService} />} />
          <Route path="/service-management/edit-service" element={<PrivateRoute component={AddService} />} />
          <Route path="/service-management/service-details" element={<PrivateRoute component={ServiceDetail} />} />
          <Route path="/blogs-management" element={<PrivateRoute component={BlogsMgmt} />} />
          <Route path="/blogs-management/add-blogs" element={<PrivateRoute component={AddBlogs} />} />
          <Route path="/blogs-management/edit-blogs" element={<PrivateRoute component={AddBlogs} />} />
          <Route path="/blogs-management/blog-details" element={<PrivateRoute component={BlogDetails} />} />
          <Route path="/blogs-management/clone-blogs" element={<PrivateRoute component={AddBlogs} />} />


          <Route path="/blogs-management/category" element={<PrivateRoute component={Category} />} />
          <Route path="/banner-management" element={<PrivateRoute component={BannerMgmt} />} />

          <Route path="/support-management/:type" element={<PrivateRoute component={SupportMgmt} />} />
          <Route path="/support-management/view-details" element={<PrivateRoute component={ViewDetails} />} />

          <Route path="/astrologer-management/:type" element={<PrivateRoute component={NewRegister} />} />
          <Route path="/new-astrologer-details" element={<PrivateRoute component={NewRegisterDetail} />} />
          <Route path="/astrologer-management/approved-astrologers" element={<PrivateRoute component={ApprovedAstrologer} />} />
          <Route path="/astrologer-management/approved-astrologer-details/:type" element={<PrivateRoute component={ApprovedDetail} />} />



          <Route path="/appointment-management/:type" element={<PrivateRoute component={AppointmentMgmt} />} />
          <Route path="/appointment-management/upcoming-details" element={<PrivateRoute component={UpcomingDetail} />} />
          <Route path="/appointment-management/reschedule-details" element={<PrivateRoute component={RescheduleDetail} />} />
          <Route path="/appointment-management/completed-details" element={<PrivateRoute component={CompletedDetail} />} />
          <Route path="/appointment-management/noshow-details" element={<PrivateRoute component={NoshowDetail} />} />




          <Route path="/payment-management/transaction-details" element={<PrivateRoute component={TransactionDetail} />} />
          <Route path="/payment-management/revenue-details" element={<PrivateRoute component={RevenueDetail} />} />

          <Route path="/subadmin-management" element={<PrivateRoute component={SubAdminMgmt} />} />
          <Route path="/add-subadmin" element={<PrivateRoute component={AddSubAdmin} />} />
          <Route path="/edit-subadmin" element={<PrivateRoute component={AddSubAdmin} />} />
          <Route path="/sub-admin-details" element={<PrivateRoute component={SubAdminDetail} />} />

          <Route path="/notifications-management" element={<PrivateRoute component={NotificationMgmt} />} />
          <Route path="/notifications-management/add-category" element={<PrivateRoute component={AddCategory} />} />
          <Route path="/notifications-thread" element={<PrivateRoute component={NotificationThread} />} />

          <Route path="/my-account" element={<PrivateRoute component={MyAccount} />} />

        </Routes>
      </HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
}

export default App;


