import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= notificaiton list===========================//
export const notificationList = createAsyncThunk(
  "notificationMgmt/notificationList",
  async (payload) => {
    console.log("payload===>", payload);
    try {
      // debugger
      const token = isLoggedIn("AstroTechAdmin");
      const response = await axiosInstance.get(
        `notification/list?page=${payload?.page ? payload?.page : ""}&search=${
          payload?.search ? payload?.search : ""
        }&startDate=${payload?.startDate ? payload?.startDate : ""}&endDate=${
          payload?.endDate ? payload?.endDate : ""
        }&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ""}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log("Error", e);
    }
  }
);

//================================= notificaiton list===========================//
export const notificationCategoryList = createAsyncThunk(
  "notificationMgmt/notificationCategoryList",
  async (payload) => {
    console.log("payload===>", payload);
    try {
      // debugger
      const token = isLoggedIn("AstroTechAdmin");
      const response = await axiosInstance.get(
        `notification/category-list?page=${payload?.page ? payload?.page : ""}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log("Error", e);
    }
  }
);

export const addNotificationCategory = createAsyncThunk(
  "notificationMgmt/addNotificationCategory",
  async (payload) => {
    console.log("payload===>", payload);
    try {
      // debugger
      const token = isLoggedIn("AstroTechAdmin");
      const response = await axiosInstance.post(
        `notification/add-category`,
        payload,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log("Error", e);
    }
  }
);

export const addNotification = createAsyncThunk(
  "notificationMgmt/addNotification",
  async (payload) => {
    console.log("payload===>", payload);
    try {
      // debugger
      const token = isLoggedIn("AstroTechAdmin");
      if (payload?.notificationId) {
        const response = await axiosInstance.put(
          `notification/edit-notification`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        return response.data;
      } else {
        const response = await axiosInstance.post(
          `notification/add-notification`,
          payload,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        return response.data;
      }
    } catch (e) {
      console.log("Error", e);
    }
  }
);

//================================= thread list===========================//
export const notificationThreadList = createAsyncThunk(
  "notificationMgmt/notificationThreadList",
  async (payload) => {
    console.log("payload===>", payload);
    try {
      // debugger
      const token = isLoggedIn("AstroTechAdmin");
      const response = await axiosInstance.get(
        `notification/notification-by-category-list?categoryId=${payload?.categoryId ? payload?.categoryId : ""}&page=${
          payload?.page ? payload?.page : ""
        }&search=${payload?.search ? payload?.search : ""}&startDate=${
          payload?.startDate ? payload?.startDate : ""
        }&endDate=${payload?.endDate ? payload?.endDate : ""}&timeFrame=${
          payload?.timeFrame ? payload?.timeFrame : ""
        }`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log("Error", e);
    }
  }
);

const initialState = {
  getNotificationList: "",
  getNotificationCategoryList: "",
  getNotificationThreadList: "",
  loader: "",
};

const notificationMgmtSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(notificationList.pending, (state) => {
      state.loader = true;
      state.getNotificationList = {};
    });
    builder.addCase(notificationList.fulfilled, (state, action) => {
      state.loader = false;
      state.getNotificationList = action.payload;
    });
    builder.addCase(notificationList.rejected, (state, action) => {
      state.loader = false;
      state.getNotificationList = action.error.message;
    });
    builder.addCase(notificationCategoryList.pending, (state) => {
      state.loader = true;
      state.getNotificationCategoryList = {};
    });
    builder.addCase(notificationCategoryList.fulfilled, (state, action) => {
      state.loader = false;
      state.getNotificationCategoryList = action.payload;
    });
    builder.addCase(notificationCategoryList.rejected, (state, action) => {
      state.loader = false;
      state.getNotificationCategoryList = action.error.message;
    });
    builder.addCase(notificationThreadList.pending, (state) => {
      state.loader = true;
      state.getNotificationThreadList = {};
    });
    builder.addCase(notificationThreadList.fulfilled, (state, action) => {
      state.loader = false;
      state.getNotificationThreadList = action.payload;
    });
    builder.addCase(notificationThreadList.rejected, (state, action) => {
      state.loader = false;
      state.getNotificationThreadList = action.error.message;
    });
  },
});

export default notificationMgmtSlice.reducer;
