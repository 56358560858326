import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  notificationList,
} from "../../Redux/Slices/notificationMgmt";
import moment from "moment";
import { BeatLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import AddNotificationModal from "../Modals/AddNotificationModal";
import StatusModal from "../Modals/StatusModal";
import { Modal } from "react-bootstrap";
import { commomObj } from "../../Utils/util";
import { toast } from "react-toastify";

const initialState = {
  search: "",
  fromDate: "",
  toDate: "",
  timeFrame: "",
  error: "",
  addModal: false,
  editModal: false,
  statusModal: false,
  id: "",
  status: "",
  type: "",
  title: "notification",
  userType: "",
  category: "",
  notifi_content: "",
  whatsApp: false,
  sms: false,
  email: false,
  web: false,
  disable: false,
  actionResponse:false
};

const NotificationMgmt = () => {
  const location = useLocation();
  const heading = location?.state;
  const dispatch = useDispatch();
  const { getNotificationList, loader } = useSelector(
    (state) => state.notificationMgmt
  );
  const [iState, updateState] = useState(initialState);
  const {
    search,
    fromDate,
    toDate,
    timeFrame,
    error,
    type,
    editModal,
    title,
    notifi_content,
    disable,
    userType,
    id,
    actionResponse
  } = iState;
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "timeFrame") {
      updateState({
        ...iState,
        [name]: value,
        fromDate: "",
        toDate: "",
        error: "",
      });
    } else if (name == "fromDate" || name == "toDate") {
      updateState({ ...iState, [name]: value, timeFrame: "", error: "" });
    } else {
      updateState({ ...iState, [name]: value, error: "" });
    }
  };

  const handleEdit = () => {
    updateState({ ...iState, disable: true });
    const data = {
      notificationId: id,
      title,
      content: notifi_content,
      userType,
    };
    dispatch(addNotification(data))
    .then((res) => {
      dispatch(notificationList());
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message, commomObj);
        updateState(initialState);
      } else {
        toast.error(res?.payload?.message, commomObj);
      }
    });
  };

  const handleStatusShow = (item, type) => {
    updateState({
      ...iState,
      statusModal: true,
      status: "delete",
      id: item?._id,
      type,
    });
  };

  const handleRefresh = () => {
    updateState(initialState);
    dispatch(notificationList());
  };

  const handleFilterApply = () => {
    if (fromDate == "" && toDate !== "") {
      updateState({ ...iState, error: "*Please select From Date" });
    } else if (fromDate !== "" && toDate == "") {
      updateState({ ...iState, error: "*Please select To Date" });
    } else if (fromDate == "" && toDate == "" && search?.trim() == "") {
      updateState({ ...iState, error: "*Please select atleast one filter" });
    } else {
      const data = {
        search,
        startDate: fromDate,
        endDate: toDate,
        timeFrame,
      };
      dispatch(notificationList(data))
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    const data = {
      page: pageNumber,
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
    };
    dispatch(notificationList(data));
  };

  

  useEffect(() => {
    dispatch(
      notificationList({
        search,
        startDate: fromDate,
        endDate: toDate,
        timeFrame,
      })
    );
  }, [timeFrame]);

  useEffect(() => {
    if (actionResponse) {
        dispatch(notificationList())
            .then(res => {
                type == "notification/delete-notification" &&
                    toast.success("Notification deleted Successfully");
                updateState(initialState);
            })
    }
}, [actionResponse]);

  console.log({ getNotificationList });

  return (
    <>
      <Header heading={heading} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title"></h4>
            <div>
              <Link
                to="/notifications-management/add-category"
                className="TitleLink"
                style={{ marginRight: "5px" }}
                state="Add Category"
              >
                Add Category
              </Link>
              <a
                className="TitleLink"
                onClick={() => updateState({ ...iState, addModal: true })}
              >
                Add New Notification
              </a>
            </div>
          </div>
          <div className="Small-Wrapper">
            <div className="FilterArea">
              <div className="FilterLeft">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here.."
                    name="search"
                    value={search}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="toDate"
                    value={toDate}
                    onChange={handleInputChange}
                    className="form-control"
                    min={fromDate}
                  />
                </div>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <button className="Button" onClick={handleFilterApply}>
                    Apply
                  </button>

                  <button
                    className="Button Cancel"
                    onClick={handleRefresh}
                    style={{ marginLeft: "6px" }}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
              <div className="FilterRight">
                <div className="form-group">
                  <label>Timeframe</label>
                  <select
                    className="form-control"
                    name="timeFrame"
                    onClick={handleInputChange}
                  >
                    <option value="" selected={timeFrame == ""}>
                      Select{" "}
                    </option>
                    <option value="Today">Today</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                  </select>
                </div>
              </div>
            </div>
            <span className="error">{error}</span>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Title of Notifications</th>
                  <th>Message</th>
                  <th>User Type</th>
                  <th>To User</th>
                  <th>No. of Users</th>
                  <th>Sent On</th>
                  {/* <th>Thread/Category</th> */}
                  <th>Resend</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getNotificationList?.data?.result?.length > 0 ? (
                  getNotificationList?.data?.result?.map((item, i) => {
                    return (
                      <tr>
                        <td>
                          {" "}
                          {(i + 1 + serialNo - 10).toString().padStart(3, "0")}
                        </td>
                        <td>{item?.title}</td>
                        <td>{item?.content}</td>
                        <td>{item?.userType}</td>
                        <td>--</td>
                        <td>{item?.noOfUsers}</td>
                        <td>
                          {moment(item?.createdAt).format("DD-MM-YYYY  h:mm A")}
                        </td>
                        <td>
                          <div className="Actions justify-content-start">
                            <a
                              href="javascript:void(0)"
                              className="Resend ml-0"
                            >
                              Resend
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="Actions">
                            <a
                              className="Green"
                              onClick={() =>
                                updateState({
                                  ...iState,
                                  editModal: true,
                                  id: item?._id,
                                  title:item?.title,
                                  notifi_content:item?.content,
                                  userType:item?.userType
                                })
                              }
                            >
                              <i className="fa fa-pencil" />
                            </a>
                            <a
                              className="Red"
                              onClick={() =>
                                handleStatusShow(
                                  item,
                                  "notification/delete-notification"
                                )
                              }
                            >
                              <i className="fa-solid fa-box-archive" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !loader ? (
                  <tr>
                    <td colSpan="10">
                      <p className="center">No Data found.</p>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            {loader ? (
              <p className="load">
                <BeatLoader loading={loader} size={10} color="#fd701e" />
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="pagination">
            <ul>
              {getNotificationList?.data?.total > 0 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={getNotificationList?.data?.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              )}
            </ul>
          </div>
        </div>
      </div>
      <AddNotificationModal
        iState={iState}
        updateState={updateState}
        initialState={initialState}
      />
      <StatusModal iState={iState} updateState={updateState} type={type} />
      <Modal
        show={editModal}
        onHide={() => updateState(initialState)}
        className="ModalBox"
      >
        <Modal.Body>
          <div className="Category">
            <a onClick={() => updateState(initialState)} className="CloseModal">
              ×
            </a>
            <h3>Edit Notification</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>User Type</label>
                  <select
                    className="form-control"
                    name="userType"
                    onClick={handleInputChange}
                  >
                    <option value="" selected={userType==""}>Select User Type</option>
                    <option value="user" selected={userType=="user"}>App User</option>
                    <option value="astrologer" selected={userType=="astrologer"}>Astrologer</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Notification Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title"
                    name="title"
                    value={title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>Notification Content</label>
                  <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Enter Content"
                    defaultValue={""}
                    name="notifi_content"
                    value={notifi_content}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="Buttons">
              <button
                className="Button ml-0"
                data-dismiss="modal"
                disabled={disable}
                onClick={handleEdit}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NotificationMgmt;
