import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  addNotification,
  notificationCategoryList,
  notificationList,
} from "../../Redux/Slices/notificationMgmt";
import { toast } from "react-toastify";
import { commomObj } from "../../Utils/util";

const AddNotificationModal = ({ iState, updateState, initialState }) => {
  const {
    addModal,
    title,
    userType,
    category,
    notifi_content,
    whatsApp,
    sms,
    email,
    web,
    disable,
  } = iState;

  const { getNotificationCategoryList } = useSelector(
    (state) => state.notificationMgmt
  );
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (
      name == "web" ||
      name == "sms" ||
      name == "email" ||
      name == "whatsApp"
    ) {
      updateState({ ...iState, [name]: checked });
    } else {
      updateState({ ...iState, [name]: value });
    }
  };

  const handleAddNotification = () => {
    updateState({ ...iState, disable: true });
    const data = {
      title,
      userType,
      categoryId: category,
      content: notifi_content,
      whatsApp,
      web,
      sms,
      email,
    };

    dispatch(addNotification(data))
    .then((res) => {
      dispatch(notificationList());
      if (res?.payload?.status == 200) {
        toast.success(res?.payload?.message, commomObj);
        updateState(initialState);
      } else {
        toast.error(res?.payload?.message, commomObj);
      }
    });
  };

  useEffect(() => {
    dispatch(notificationCategoryList());
  }, []);

  console.log("sadasd");

  return (
    <>
      <Modal
        show={addModal}
        onHide={() => updateState(initialState)}
        className="ModalBox"
      >
        <Modal.Body>
          <div className="Category">
            <a
              className="CloseModal"
              data-dismiss="modal"
              onClick={() => updateState(initialState)}
            >
              ×
            </a>
            <h3>Add New Notification</h3>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Title"
                name="title"
                value={title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>User Type</label>
              <select
                className="form-control"
                name="userType"
                onClick={handleInputChange}
              >
                <option value="">Select User Type</option>
                <option value="user">App User</option>
                <option value="astrologer">Astrologer</option>
              </select>
            </div>
            <div className="form-group">
              <label>Category</label>
              <select
                className="form-control"
                name="category"
                onClick={handleInputChange}
              >
                <option value="">Select Category</option>
                {getNotificationCategoryList?.data?.result?.length > 0
                  ? getNotificationCategoryList?.data?.result?.map(
                      (item, i) => {
                        return (
                          <option value={item?._id} key={i}>
                            {item?.categoryName}
                          </option>
                        );
                      }
                    )
                  : ""}
              </select>
            </div>
            <div className="form-group">
              <label>Notification Content</label>
              <textarea
                className="form-control"
                rows={4}
                placeholder="Enter Content"
                defaultValue={""}
                name="notifi_content"
                value={notifi_content}
                onChange={handleInputChange}
              />
            </div>
            <ul className="NotificationUl">
              <li>
                <label className="CheckBox">
                  WhatsApp
                  <input
                    type="checkbox"
                    name="whatsApp"
                    value={whatsApp}
                    onChange={handleInputChange}
                  />
                  <span className="checkmark" />
                </label>
              </li>
              <label className="CheckBox">
                SMS
                <input
                  type="checkbox"
                  name="sms"
                  value={sms}
                  onChange={handleInputChange}
                />
                <span className="checkmark" />
              </label>
              <label className="CheckBox">
                Email
                <input
                  type="checkbox"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
                <span className="checkmark" />
              </label>
              <label className="CheckBox">
                Web Notification
                <input
                  type="checkbox"
                  name="web"
                  value={web}
                  onChange={handleInputChange}
                />
                <span className="checkmark" />
              </label>
            </ul>
            <div className="Buttons">
              <button
                className="Button ml-0"
                data-dismiss="modal"
                onClick={handleAddNotification}
                disabled={disable}
              >
                {" "}
                Send Notification
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNotificationModal;
