import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotificationCategory,
  notificationCategoryList,
} from "../../Redux/Slices/notificationMgmt";
import Pagination from "react-js-pagination";
import { BeatLoader } from "react-spinners";
import StatusModal from "../Modals/StatusModal";
import { toast } from "react-toastify";

const initialState = {
  categoryName: "",
  disable: false,
  error: "",
  statusModal: false,
  id: "",
  status: "",
  type: "",
  actionResponse:false,
  title:"category"
};

const AddCategory = () => {
  const [iState, updateState] = useState(initialState);
  const { categoryName, disable, error,type ,actionResponse} = iState;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);
  const { getNotificationCategoryList, loader } = useSelector(
    (state) => state.notificationMgmt
  );
  const heading = location?.state;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value, error: "" });
  };

  const handleStatusShow = (item, type) => {
    updateState({
      ...iState,
      statusModal: true,
      status: "delete",
      id: item?._id,
      type,
    });
  };

  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    const data = {
      page: pageNumber,
    };
    dispatch(notificationCategoryList(data));
  };

  const handleAdd = () => {
    if (!categoryName) {
      updateState({ ...iState, error: "*This field is required" });
    } else {
      updateState({ ...iState, disable: true });
      dispatch(addNotificationCategory({ categoryName })).then(() => {
        updateState(initialState);
        dispatch(notificationCategoryList());
      });
    }
  };

  useEffect(() => {
    dispatch(notificationCategoryList());
  }, []);

  useEffect(() => {
    if (actionResponse) {
        dispatch(notificationCategoryList())
            .then(res => {
                type == "notification/delete-category" &&
                    toast.success("Category deleted Successfully");
                updateState(initialState);
            })
    }
}, [actionResponse]);

  console.log({ getNotificationCategoryList });

  return (
    <>
      <Header heading={heading} />
      <SideNav />
      <>
        <div className="WrapperArea">
          <div className="WrapperBox">
            <div className="TitleBox">
              <h4 className="Title"></h4>
              <div className="BackArrow">
                <Link
                  // to="/notifications-management"
                  onClick={()=>navigate(-1)}
                  state="Notification Management"
                >
                  <i className="fa fa-long-arrow-left" /> Back
                </Link>
              </div>
            </div>
            <div className="Small-Wrapper">
              <div className="CommonForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Add Category</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter category"
                        name="categoryName"
                        value={categoryName}
                        onChange={handleInputChange}
                      />
                      <span className="error">{error}</span>
                    </div>
                  </div>
                </div>
                <button
                  className="Button"
                  disabled={disable}
                  onClick={handleAdd}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="TableList">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Category Name</th>
                    <th>Count</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getNotificationCategoryList?.data?.result?.length > 0 ? (
                    getNotificationCategoryList?.data?.result?.map((item, i) => {
                      return (
                        <tr>
                          <td>
                            {" "}
                            {(i + 1 + serialNo - 10)
                              .toString()
                              .padStart(3, "0")}
                          </td>
                          <td>
                            <Link
                              to="/notifications-thread"
                              state={{id:item?._id,heading:"Notification Thread/Category"}}
                            >
                              {item?.categoryName}
                            </Link>
                          </td>
                          <td>{item?.notificationCount}</td>
                          <td>
                            <div className="Actions">
                              <a
                                className="Red"
                                data-toggle="modal"
                                onClick={() =>
                                  handleStatusShow(
                                    item,
                                    "notification/delete-category"
                                  )
                                }
                              >
                                <i className="fa fa-trash" />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : !loader ? (
                    <tr>
                      <td colSpan="10">
                        <p className="center">No Data found.</p>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
              {loader ? (
                <p className="load">
                  <BeatLoader loading={loader} size={10} color="#fd701e" />
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="pagination">
              <ul>
                {getNotificationCategoryList?.data?.total > 0 && (
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={getNotificationCategoryList?.data?.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
        <StatusModal iState={iState} updateState={updateState} type={type} />
      </>
    </>
  );
};

export default AddCategory;
