import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { astrologerList } from "../../Redux/Slices/astrologerMgmt";
import { toast } from "react-toastify";
import { commomObj } from "../../Utils/util";
import { BeatLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import StatusModal from "../Modals/StatusModal";

const initialState = {
  statusModal: false,
  id: "",
  type: "",
  search: "",
  fromDate: "",
  toDate: "",
  timeFrame: "",
  error: "",
  title: "Astrologer",
  actionResponse:false,
};

const ApprovedAstrologer = () => {
  const [iState, updateState] = useState(initialState);
  const { search, fromDate, toDate, error, timeFrame, id, type,actionResponse } = iState;

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAstrologerList, loader } = useSelector(
    (state) => state.astrologerMgmt
  );
  const heading = location?.state;
  const [activePage, updateActivePage] = useState(1);
  const [serialNo, updateSerialNo] = useState(10);

  const handlePageChange = (pageNumber) => {
    updateActivePage(pageNumber);
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    const data = {
      page: pageNumber,
      astroStatus: "approved",
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
    };
    dispatch(astrologerList(data));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "timeFrame") {
      updateState({
        ...iState,
        [name]: value,
        fromDate: "",
        toDate: "",
        error: "",
      });
    } else if (name == "fromDate" || name == "toDate") {
      updateState({ ...iState, [name]: value, timeFrame: "", error: "" });
    } else {
      updateState({ ...iState, [name]: value, error: "" });
    }
  };

  const handleRefresh = () => {
    updateState(initialState);
    updateSerialNo(10);
    dispatch(astrologerList({ astroStatus: "approved" }));
  };

  const handleFilterApply = () => {
    if (fromDate == "" && toDate !== "") {
      updateState({ ...iState, error: "*Please select From Date" });
    } else if (fromDate !== "" && toDate == "") {
      updateState({ ...iState, error: "*Please select To Date" });
    } else if (fromDate == "" && toDate == "" && search?.trim() == "") {
      updateState({ ...iState, error: "*Please select atleast one filter" });
    } else {
      const data = {
        search,
        startDate: fromDate,
        endDate: toDate,
        astroStatus: "approved",
        timeFrame,
      };
      dispatch(astrologerList(data))
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleStatusShow = (item, type) => {
    updateState({
      ...iState,
      statusModal: true,
      status: item?.isActive,
      id: item?._id,
      type,
    });
  };

  useEffect(() => {
    const data = {
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
      astroStatus: "approved",
    };
    dispatch(astrologerList(data))
    .then((result) => {
      console.log({ result });
      if (result?.payload?.status == 401) {
        toast.error("Invalid Token.Please Login again", commomObj);
        navigate("/");
      }
    })
    .catch((err) => {
      console.log({ err });
    });
    
  }, [timeFrame]);

  useEffect(() => {
    if (actionResponse) {
        dispatch(astrologerList({ astroStatus: "approved" }))
            .then(res => {
                type == "astrologer/status-update" &&
                    toast.success("Status Updated Successfully");
                updateState(initialState);
            })
    }
}, [actionResponse]);

  return (
    <>
      <Header heading={heading} />
      <SideNav />

      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title"></h4>
            <a className="TitleLink" href="javascript:void(0);">
              <i className="fa fa-download" /> Download Excel
            </a>
          </div>
          <div className="Small-Wrapper">
            <div className="FilterArea">
              <div className="FilterLeft">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here.."
                    name="search"
                    value={search}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="toDate"
                    value={toDate}
                    onChange={handleInputChange}
                    className="form-control"
                    min={fromDate}
                  />
                </div>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <button className="Button" onClick={handleFilterApply}>
                    Apply
                  </button>

                  <button
                    className="Button Cancel"
                    onClick={handleRefresh}
                    style={{ marginLeft: "6px" }}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
              <div className="FilterRight">
                <div className="form-group">
                  <label>Timeframe</label>
                  <select
                    className="form-control"
                    name="timeFrame"
                    onClick={handleInputChange}
                  >
                    <option value="" selected={timeFrame == ""}>
                      Select{" "}
                    </option>
                    <option value="Today">Today</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                  </select>
                </div>
              </div>
            </div>
            <span className="error">{error}</span>
          </div>
          <div className="TableList">
            {/* <a href='https://meet.google.com/fmq-vynb-uux'>https://meet.google.com/fmq-vynb-uux</a> */}
            <table style={{ width: "150%" }}>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Astrologer ID</th>
                  <th>
                    Display <br /> Name
                  </th>
                  <th>
                    Contact <br /> Number
                  </th>
                  <th>
                    Email <br /> ID
                  </th>
                  <th>City</th>
                  <th>Approved On</th>
                  <th>
                    Experience <br /> (in Years)
                  </th>
                  {/* <th>Services</th> */}
                  <th>Ratings</th>
                  <th>
                    Pay Out (in Rs.)
                    <br />
                    30/45/60 min
                  </th>
                  <th>
                    Total Completed <br /> Appointments
                  </th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getAstrologerList?.data?.result?.length > 0 ? (
                  getAstrologerList?.data?.result?.map((item, i) => {
                    return (
                      <tr>
                        <td> {(i + 1 + serialNo - 10).toString().padStart(3, "0")}</td>
                        <td>{item?.astroNo}</td>
                        <td>{item?.fullName}</td>
                        <td>{item?.contact}</td>
                        <td>{item?.email}</td>
                        <td>{item?.city}</td>
                        <td>{item?.approveDate?.split("T")?.at(0)}</td>
                        <td>{item?.experience} Years</td>
                        {/* <td>Vastu</td> */}
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>
                          {item?.isActive ? (
                            <span className="Green">Active</span>
                          ) : (
                            <span className="Red">InActive</span>
                          )}
                        </td>
                        <td>
                          <div className="Actions">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item?.isActive}
                                onClick={() =>
                                  handleStatusShow(
                                    item,
                                    "astrologer/status-update"
                                  )
                                }
                              />
                              <span className="slider" />
                            </label>
                            <Link
                              className="Blue"
                              to="/astrologer-management/approved-astrologer-details/personal-detail"
                              state={{
                                itemId: item?._id,
                                heading: "Approved Astrologers",
                              }}
                            >
                              <i className="fa fa-eye" />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !loader ? (
                  <tr>
                    <td colSpan="10">
                      <p className="center">No Data found.</p>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            {loader ? (
              <p className="load">
                <BeatLoader loading={loader} size={10} color="#fd701e" />
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="pagination">
            <ul>
              {getAstrologerList?.data?.approvedTotal > 0 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={getAstrologerList?.data?.approvedTotal}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="ModalBox">
        <div id="UserDelete" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Decline">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Delete</h3>
                  <p>Are you sure you want to delete this User ?</p>
                  <h4>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      no
                    </a>
                    <a href="javascript:void(0);" data-dismiss="modal">
                      Yes
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ProjectAdminAdd" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Project Admin Add</h3>
                  <div className="form-group">
                    <label>Project Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Contact Person</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Contact Number</label>
                    <input type="text" className="form-control" />
                    <span className="Verify">Verify </span>
                  </div>
                  <div className="form-group">
                    <label>Email ID</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Upload Photo of User</label>
                    <input type="file" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Confirm password</label>
                    <input type="text" className="form-control" />
                  </div>
                  <button className="Button">Create </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="ProjectAdminEdit" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Edit Project Admin</h3>
                  <div className="form-group">
                    <label>Project Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Contact Person</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Contact Number</label>
                    <input type="text" className="form-control" />
                    <span className="Verify">Verify </span>
                  </div>
                  <div className="form-group">
                    <label>Email ID</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Upload Photo of User</label>
                    <input type="file" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label>Confirm password</label>
                    <input type="text" className="form-control" />
                  </div>
                  <button className="Button">Submit </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StatusModal iState={iState} updateState={updateState} type={type} />
    </>
  );
};

export default ApprovedAstrologer;
