import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const appointmentList = createAsyncThunk(
    "appointmentMgmt/appointmentList",
    async (payload) => {
        console.log("payload===>", payload);    
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`appointment/appointment-list?bookingStatus=${payload?.bookingStatus ? payload?.bookingStatus : ''}&page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);



const initialState = {

    getAppointmentList: '',
    loader: ''
}

const appointmentMgmtSlice = createSlice({
    name: 'appointment',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(appointmentList.pending, (state) => {
            state.loader = true;
            state.getAppointmentList = {}
        })
        builder.addCase(appointmentList.fulfilled, (state, action) => {
            state.loader = false;
            state.getAppointmentList = action.payload;
        })
        builder.addCase(appointmentList.rejected, (state, action) => {
            state.loader = false;
            state.getAppointmentList = action.error.message;
        });
        
    }
})


export default appointmentMgmtSlice.reducer;