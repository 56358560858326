import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { Nav, Tab } from "react-bootstrap";
import { getPathName } from "../../Utils/util";
import { useDispatch, useSelector } from "react-redux";
import { appointmentList } from "../../Redux/Slices/appointmentMgmt";
import { BeatLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import moment from "moment";

const initialState = {
  search: "",
  fromDate: "",
  toDate: "",
  timeFrame: "",
  error: "",
};

const AppointmentMgmt = () => {
  const [iState, updateState] = useState(initialState);
  const { search, fromDate, toDate, timeFrame, error } = iState;
  const [serialNo, updateSerialNo] = useState(10);
  const [activePage, updateActivePage] = useState(1);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "timeFrame") {
      updateState({
        ...iState,
        [name]: value,
        fromDate: "",
        toDate: "",
        error: "",
      });
    } else if (name == "fromDate" || name == "toDate") {
      updateState({ ...iState, [name]: value, timeFrame: "", error: "" });
    } else {
      updateState({ ...iState, [name]: value, error: "" });
    }
  };

  const handlePageChange = (pageNumber) => {
    let ser = pageNumber * 10;
    updateSerialNo(ser);
    updateActivePage(pageNumber);
    const data = {
      bookingStatus: pathname,
      page: pageNumber,
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
    };
    dispatch(appointmentList(data));
  };

  const handleRefresh = () => {
    updateState(initialState);
    updateSerialNo(10);
    dispatch(appointmentList({ bookingStatus: pathname }));
  };

  const handleFilterApply = () => {
    if (fromDate == "" && toDate !== "") {
      updateState({ ...iState, error: "*Please select From Date" });
    } else if (fromDate !== "" && toDate == "") {
      updateState({ ...iState, error: "*Please select To Date" });
    } else if (fromDate == "" && toDate == "" && search?.trim() == "") {
      updateState({ ...iState, error: "*Please select atleast one filter" });
    } else {
      const data = {
        search,
        startDate: fromDate,
        endDate: toDate,
        timeFrame,
        bookingStatus: pathname,
      };
      dispatch(appointmentList(data))
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const pathname = getPathName();
  const location = useLocation();
  const { type } = useParams();
  const dispatch = useDispatch();
  const { getAppointmentList, loader } = useSelector(
    (state) => state.appointmentMgmt
  );
  const heading = location?.state;

  useEffect(() => {
    const data = {
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
      bookingStatus: pathname,
    };
    dispatch(appointmentList(data));
  }, [pathname, timeFrame]);

  console.log({ getAppointmentList });

  return (
    <>
      <Header heading={heading} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <Tab.Container defaultActiveKey={type}>
            <div className="CommonTabs">
              <Nav className="nav-tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="upcoming"
                    as={Link}
                    to="/appointment-management/upcoming"
                    state="Appointment Management"
                  >
                    {" "}
                    Upcoming{" "}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="completed"
                    as={Link}
                    to="/appointment-management/completed"
                    state="Appointment Management"
                  >
                    {" "}
                    Completed
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="canceled"
                    as={Link}
                    to="/appointment-management/canceled"
                    state="Appointment Management"
                  >
                    {" "}
                    Rescheduled/Cancelled
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="noshow"
                    as={Link}
                    to="/appointment-management/noshow"
                    state="Appointment Management"
                  >
                    {" "}
                    No Show
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content className="tab-content">
              <div className="TitleBox justify-content-end m-0">
                <a
                  href="javascript:void(0);"
                  className="TitleLink"
                  style={{ marginTop: "-85px" }}
                >
                  Download CSV
                </a>
              </div>
              <div className="Small-Wrapper">
                <div className="FilterArea">
                  <div className="FilterLeft">
                    <div className="form-group">
                      <label>Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here.."
                        name="search"
                        value={search}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>From Date</label>
                      <input
                        type="date"
                        name="fromDate"
                        value={fromDate}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>To Date</label>
                      <input
                        type="date"
                        name="toDate"
                        value={toDate}
                        onChange={handleInputChange}
                        className="form-control"
                        min={fromDate}
                      />
                    </div>
                    <div className="form-group">
                      <label>&nbsp;</label>
                      <button className="Button" onClick={handleFilterApply}>
                        Apply
                      </button>

                      <button
                        className="Button Cancel"
                        onClick={handleRefresh}
                        style={{ marginLeft: "6px" }}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                  </div>
                  <div className="FilterRight">
                    <div className="form-group">
                      <label>Timeframe</label>
                      <select
                        className="form-control"
                        name="timeFrame"
                        onClick={handleInputChange}
                      >
                        <option value="" selected={timeFrame == ""}>
                          Select{" "}
                        </option>
                        <option value="Today">Today</option>
                        <option value="Week">This Week</option>
                        <option value="Month">This Month</option>
                      </select>
                    </div>
                  </div>
                </div>
                <span className="error">{error}</span>
              </div>
              <Tab.Pane className="tab-pane" eventKey="upcoming">
                <div className="TableList">
                  <table style={{ width: "130%" }}>
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Appointment ID</th>
                        <th>
                          Customer <br /> Name
                        </th>
                        <th>
                          Astrologer <br /> Name
                        </th>
                        <th>
                          Service <br /> Name
                        </th>
                        <th>
                          Type of <br /> Call
                        </th>
                        <th>
                          Duration of Call <br /> (in min)
                        </th>
                        <th>Date &amp; Time</th>
                        <th>
                          Amount Paid <br /> (in Rs)
                        </th>
                        <th>
                          View <br /> Details
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAppointmentList?.data?.result?.length > 0 ? (
                        getAppointmentList?.data?.result?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {(i + 1 + serialNo - 10)
                                  .toString()
                                  .padStart(3, "0")}
                              </td>
                              <td>{item?.bookingNo}</td>
                              <td>{item?.userData?.name}</td>
                              <td>{item?.astrologerData?.fullName}</td>
                              <td>{item?.serviceData?.serviceName}</td>
                              <td>{item?.callType}</td>
                              <td>{item?.duration}</td>
                              <td>
                                {`${item?.slots?.date} &`}
                                <br />
                                {`${item?.slots?.startTime}-${item?.slots?.endTime}`}
                              </td>
                              <td>{item?.price}</td>
                              <td>
                                <div className="Actions justify-content-start">
                                  <Link
                                    to="/appointment-management/upcoming-details"
                                    className="Blue ml-0"
                                    state={{
                                      heading: "Upcoimg Appointment Details",
                                      item,
                                    }}
                                  >
                                    <i className="fa fa-eye" />
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <div className="NewActions justify-content-center">
                                  <a
                                    href="javascript:void(0);"
                                    className="Red"
                                    data-toggle="modal"
                                    data-target="#CancelAppointment"
                                  >
                                    Cancel
                                  </a>
                                  <a
                                    href="javascript:void(0);"
                                    className="Green"
                                    data-toggle="modal"
                                    data-target="#RescheduleAppointment"
                                  >
                                    Reschedule
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : !loader ? (
                        <tr>
                          <td colSpan="10">
                            <p className="center">No Data found.</p>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  {loader ? (
                    <p className="load">
                      <BeatLoader loading={loader} size={10} color="#fd701e" />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="pagination">
                  <ul>
                    {getAppointmentList?.data?.total > 0 && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={getAppointmentList?.data?.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </ul>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="completed">
                <div className="TableList">
                  <table style={{ width: "145%" }}>
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Appointment ID</th>
                        {/* <th>Customer ID</th>  */}
                        <th>
                          Customer <br /> Name
                        </th>
                        {/* <th>Astrologer ID</th>  */}
                        <th>
                          Astrologer <br /> Name
                        </th>
                        <th>
                          Service <br /> Name
                        </th>
                        <th>Type of Call</th>
                        <th>
                          Duration of Call <br /> (in min)
                        </th>
                        <th>Date &amp; Time</th>
                        <th>
                          Amount Paid <br /> (in Rs)
                        </th>
                        {/* <th>Pay Out <br> Value</th>
                                <th>Commission <br> Deducted</th>
                                <th>Amount to <br> Astrologer</th> */}
                        <th>
                          View <br /> Feedback
                        </th>
                        <th>
                          View <br /> Details
                        </th>
                        <th>
                          View <br /> Report
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAppointmentList?.data?.result?.length > 0 ? (
                        getAppointmentList?.data?.result?.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                {(i + 1 + serialNo - 10)
                                  .toString()
                                  .padStart(3, "0")}
                              </td>
                              <td>{item?.bookingNo}</td>
                              {/* <td>c-134</td> */}
                              <td>{item?.userData?.name}</td>
                              {/* <td>c-101</td>  */}
                              <td>{item?.astrologerData?.fullName}</td>
                              <td>{item?.serviceData?.serviceName}</td>
                              <td>{item?.callType}</td>
                              <td>{item?.duration}</td>
                              <td>
                                {item?.slots?.date} <br />
                                {item?.slots?.startTime}-{item?.slots?.endTime}
                              </td>
                              <td>--</td>

                              <td>
                                <div className="Actions justify-content-start">
                                  <a href="javscript:void(0);" className="Blue">
                                    <i className="fa fa-eye" />
                                  </a>
                                </div>
                              </td>
                              <td>
                                <div className="Actions justify-content-start">
                                  <Link
                                    to="/appointment-management/completed-details"
                                    state={{
                                      heading: "Completed Appointment Details",
                                      item
                                    }}
                                    className="Blue ml-0"
                                  >
                                    <i className="fa fa-eye" />
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <div className="NewActions justify-content-center">
                                  <span className="Red CursorPointer">
                                    Report
                                  </span>
                                  <span className="Pink CursorPointer">
                                    Astrologer Notes
                                  </span>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : !loader ? (
                        <tr>
                          <td colSpan="10">
                            <p className="center">No Data found.</p>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  {loader ? (
                    <p className="load">
                      <BeatLoader loading={loader} size={10} color="#fd701e" />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="pagination">
                  <ul>
                    {getAppointmentList?.data?.total > 0 && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={getAppointmentList?.data?.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </ul>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="canceled">
                <div className="TableList">
                  <table style={{ width: "140%" }}>
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Appointment ID</th>
                        {/* <th>Customer ID</th>  */}
                        <th>
                          Customer <br /> Name
                        </th>
                        {/* <th>Astrologer ID</th>  */}
                        <th>
                          Astrologer <br /> Name
                        </th>
                        <th>
                          Service <br /> Name
                        </th>
                        <th>Type of Call</th>
                        <th>
                          Duration of Call <br /> (in min)
                        </th>
                        <th>Date &amp; Time</th>
                        <th>
                          Amount Paid <br /> (in Rs)
                        </th>
                        <th>
                          View <br /> Details
                        </th>
                        <th>Status</th>
                        <th>
                          Reason of Reschedule / <br /> Cancellation
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAppointmentList?.data?.result?.length > 0 ? (
                        getAppointmentList?.data?.result?.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                {(i + 1 + serialNo - 10)
                                  .toString()
                                  .padStart(3, "0")}
                              </td>
                              <td>{item?.bookingNo}</td>
                              <td>{item?.userData?.name}</td>
                              <td>{item?.astrologerData?.fullName}</td>
                              <td>{item?.serviceData?.serviceName}</td>
                              <td>{item?.callType}</td>
                              <td>{item?.duration}</td>
                              <td>
                                {item?.slots?.date} <br />
                                {item?.slots?.startTime}-{item?.slots?.endTime}
                              </td>
                              <td>--</td>
                              <td>
                                <div className="Actions justify-content-start">
                                  <Link
                                    to="/appointment-management/reschedule-details"
                                    className="Blue ml-0"
                                    state={{
                                      heading:
                                        "Rescheduled/Cancelled Appointment Details",
                                      item,
                                    }}
                                  >
                                    <i className="fa fa-eye" />
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <span className="Red">
                                  {item?.bookingStatus}
                                </span>
                              </td>
                              <td>--</td>
                            </tr>
                          );
                        })
                      ) : !loader ? (
                        <tr>
                          <td colSpan="10">
                            <p className="center">No Data found.</p>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  {loader ? (
                    <p className="load">
                      <BeatLoader loading={loader} size={10} color="#fd701e" />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="pagination">
                  <ul>
                    {getAppointmentList?.data?.total > 0 && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={getAppointmentList?.data?.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </ul>
                </div>
              </Tab.Pane>
              <Tab.Pane className="tab-pane" eventKey="noshow">
                <div className="TableList">
                  <table style={{ width: "135%" }}>
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Appointment ID</th>
                        {/* <th>Customer ID</th>  */}
                        <th>
                          Customer <br /> Name
                        </th>
                        {/* <th>Astrologer ID</th>  */}
                        <th>
                          Astrologer <br /> Name
                        </th>
                        <th>
                          Service <br /> Name
                        </th>
                        <th>Type of Call</th>
                        <th>
                          Duration of Call <br /> (in min)
                        </th>
                        <th>Date &amp; Time</th>
                        <th>
                          Amount Paid <br /> (in Rs)
                        </th>
                        <th>
                          No Show <br /> From
                        </th>
                        <th>View Details</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {getAppointmentList?.data?.result?.length > 0 ? (
                        getAppointmentList?.data?.result?.map((item, i) => {
                          return (
                            <tr>
                              <td>
                                {(i + 1 + serialNo - 10)
                                  .toString()
                                  .padStart(3, "0")}
                              </td>
                              <td>{item?.bookingNo}</td>
                              <td>{item?.userData?.name}</td>
                              <td>{item?.astrologerData?.fullName}</td>
                              <td>{item?.serviceData?.serviceName}</td>
                              <td>{item?.callType}</td>
                              <td>{item?.duration}</td>
                              <td>
                                {item?.slots?.date} <br />
                                {item?.slots?.startTime}-{item?.slots?.endTime}
                              </td>
                              <td>--</td>
                              <td>--</td>
                              <td>
                                <div className="Actions justify-content-start">
                                  <Link
                                    to="/appointment-management/noshow-details"
                                    className="Blue ml-0"
                                    state={{
                                      heading: "No Show Appointment Details",
                                      item
                                    }}
                                  >
                                    <i className="fa fa-eye" />
                                  </Link>
                                </div>
                              </td>
                              <td>
                                <span
                                  className="Orange CursorPointer"
                                  data-toggle="modal"
                                  data-target="#EnterRefundAmount"
                                >
                                  Refund
                                </span>
                              </td>
                            </tr>
                          );
                        })
                      ) : !loader ? (
                        <tr>
                          <td colSpan="10">
                            <p className="center">No Data found.</p>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  {loader ? (
                    <p className="load">
                      <BeatLoader loading={loader} size={10} color="#fd701e" />
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="pagination">
                  <ul>
                    {getAppointmentList?.data?.total > 0 && (
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={getAppointmentList?.data?.total}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </ul>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
      <div className="ModalBox">
        <div
          id="CancelAppointment"
          className="modal fade SmallNewModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Cancel Your Appointment</h3>
                  <div className="form-group">
                    <label>Select Your Reason</label>
                    <select className="form-control">
                      <option>Select your reason</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Additional Comments</label>
                    <textarea
                      rows={4}
                      className="form-control"
                      placeholder="Write here.."
                      defaultValue={""}
                    />
                  </div>
                  <h4 className="Heading text-center">
                    Are you sure you want to cancel <br /> your appointment ?
                  </h4>
                  <div className="Buttons">
                    <button
                      type="submit"
                      className="Button ml-0"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="RescheduleAppointment"
          className="modal fade SmallNewModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Reschedule Your Appointment</h3>
                  <div className="ModalFormArea">
                    <div className="form-group">
                      <label>Select Your Reason</label>
                      <select className="form-control">
                        <option>Select your reason</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Add Your Reason Here</label>
                      <textarea
                        rows={4}
                        className="form-control"
                        placeholder="Write reason here"
                        defaultValue={""}
                      />
                    </div>
                    <h4 className="Heading text-center">
                      Are you sure you want to reschedule <br /> your
                      appointment ?
                    </h4>
                    <div className="Buttons">
                      <button
                        type="submit"
                        className="Button"
                        data-toggle="modal"
                        data-target="#RescheduleAppointmentDate"
                        data-dismiss="modal"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="RescheduleAppointmentDate"
          className="modal fade SmallNewModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Reschedule Your Appointment</h3>
                  <div className="ModalFormArea">
                    <figure>
                      <img src="images/calendar.png" />
                    </figure>
                    <div className="Buttons">
                      <button
                        type="submit"
                        className="Button"
                        data-dismiss="modal"
                      >
                        Reschedule
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="EnterRefundAmount"
          className="modal fade SmallNewModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Enter Refund Amount</h3>
                  <form>
                    <div className="form-group">
                      <label>Amount Paid</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter amount"
                      />
                    </div>
                    <div className="form-group">
                      <label>No Show From</label>
                      <select className="form-control">
                        <option>--select--</option>
                        <option>Customer</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Refund Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Refund Amount"
                      />
                    </div>
                    <div className="Buttons">
                      <button
                        type="submit"
                        className="Button ml-0"
                        data-dismiss="modal"
                      >
                        Initiate Refund
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="UserAdd" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Create User</h3>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Number "
                    />
                  </div>
                  <div className="form-group">
                    <label>Email ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email ID"
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address"
                    />
                  </div>
                  <div className="form-group">
                    <label>Upload Photo of User</label>
                    <input type="file" className="form-control" />
                  </div>
                  <button className="Button">Create</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="UserEdit" className="modal fade" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className="Category">
                  <a
                    href="javascript:void(0);"
                    className="CloseModal"
                    data-dismiss="modal"
                  >
                    ×
                  </a>
                  <h3>Edit User</h3>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Number "
                    />
                  </div>
                  <div className="form-group">
                    <label>Email ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email ID"
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Address"
                    />
                  </div>
                  <div className="form-group">
                    <label>Upload Photo of User</label>
                    <input type="file" className="form-control" />
                  </div>
                  <button className="Button">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentMgmt;
