import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Modal, Nav, Tab } from 'react-bootstrap'
import { commomObj, getPathName, formatDateWithAmPm } from '../../Utils/util'
import { useDispatch, useSelector } from 'react-redux'
import { supportList, updateStatus } from '../../Redux/Slices/supportMgmt'
import { toast } from 'react-toastify'
import { BeatLoader } from 'react-spinners'
import Pagination from 'react-js-pagination'



const initialState = {
    requestStatus: "",
    currentStatus: '',
    updatedStatus: '',
    date: '',
    message: '',
    requestId: '',
    updateStatusModal: false,
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    errors: '',
}

const SupportMgmt = () => {


    const [iState, updateState] = useState(initialState);
    const { requestStatus, updateStatusModal, requestId, currentStatus, updatedStatus, date, message, search, fromDate, toDate, timeFrame, error, errors } = iState;
    const d = new Date();

    const { type } = useParams();
    const location = useLocation();
    const pathname = getPathName();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getSupportList, loader } = useSelector(state => state.supportMgmt);
    const [activePage, updateActivePage] = useState(1);
    const heading = location?.state;



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'timeFrame') {
            updateState({ ...iState, [name]: value, fromDate: '', toDate: '', error: '', errors: '' });
        }
        else if (name == "fromDate" || name == "toDate") {
            updateState({ ...iState, [name]: value, timeFrame: '', error: '', errors: '' });
        }
        else {
            updateState({ ...iState, [name]: value, error: '', errors: "" });
        }
    }

    const updateStatusModalHideShow = (type, id, currentStatus) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, updateStatusModal: true, requestId: id, currentStatus, error: '' })
        }
    }

    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!currentStatus) {
            formIsValid = false;
        }
        if (!updatedStatus) {
            error.updatedStatusError = " *Status is mandetory";
            formIsValid = false;
        }
        // if (!date) {
        //     error.dateError = " *Date is mandetory";
        //     formIsValid = false;
        // }
        if (!message) {
            error.messageError = " *Message is mandetory";
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }


    const handleUpdate = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                requestId,
                currentStatus,
                updatedStatus,
                date: d.toISOString()?.split("T")?.[0],
                message
            }
            dispatch(updateStatus(data))
                .then((result) => {
                    if (result?.payload?.status == 200) {
                        updateState(initialState)
                        toast.success(result?.payload?.message, commomObj);
                        dispatch(supportList({ requestStatus: pathname }));
                    }
                    else {
                        toast.error(result?.payload?.message, commomObj);
                    }
                    console.log({ result })
                }).catch((err) => {
                    console.log(err)
                });
        }
    }

    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
            requestStatus,
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame
        }
        dispatch(supportList(data))
    };


    const handleRefresh = () => {
        updateState(initialState);
        dispatch(supportList({ requestStatus: pathname }));
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '') {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                requestStatus: pathname,
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame
            }
            dispatch(supportList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        // if (timeFrame) {
        const data = {
            requestStatus: pathname,
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame,
        }
        dispatch(supportList(data))
        // }

    }, [timeFrame])

    useEffect(() => {

        updateState({ ...initialState, requestStatus: pathname })
        dispatch(supportList({ requestStatus: pathname }))
            .then((result) => {
                console.log({ result })
                if (result?.payload?.status == 401) {
                    toast.error("Invalid Token.Please Login again", commomObj);
                    navigate('/');
                }
            }).catch((err) => {
                console.log({ err })
            });

    }, [pathname])

    console.log({ date })


    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                    </div>
                    <Tab.Container defaultActiveKey={type}>
                        <div className="CommonTabs">
                            <Nav className='nav-tabs'>
                                <Nav.Item >
                                    <Nav.Link eventKey="new request" as={Link} to='/support-management/new request' state="Support Management"
                                    >  New Request{" "}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="in-progress" as={Link} to='/support-management/in-progress' state="Support Management"> In-Progress</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="resolved" as={Link} to='/support-management/resolved'
                                        state="Support Management"
                                    > Resolved</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content className="tab-content">
                            <div className="TitleBox justify-content-end m-0">
                                <a
                                    className="TitleLink"
                                    href="javascript:void(0);"
                                    style={{ marginTop: "-83px" }}
                                >
                                    <i className="fa fa-download" /> Download Excel
                                </a>
                            </div>
                            <div className="Small-Wrapper">
                                <div className="FilterArea">
                                    <div className="FilterLeft">
                                        <div className="form-group">
                                            <label>Search</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search here.."
                                                name='search'
                                                value={search}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>From Date</label>
                                            <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                        </div>
                                        <div className="form-group">
                                            <label>To Date</label>
                                            <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label>
                                            <button className="Button" onClick={handleFilterApply}>Apply</button>

                                            <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                                <i className="fa fa-refresh" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="FilterRight">
                                        <div className="form-group">
                                            <label>Timeframe</label>
                                            <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                                <option value='' selected={timeFrame == ""}>Select </option>
                                                <option value="Today">Today</option>
                                                <option value="Week">This Week</option>
                                                <option value="Month">This Month</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <span className='error'>{error}</span>
                            </div>
                            <Tab.Pane className="tab-pane" eventKey='new request'>
                                <div className="TableList">
                                    <table style={{ width: "130%" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Enquiry ID</th>
                                                <th>Enquiry Date &amp; Time</th>
                                                <th>Username</th>
                                                <th>Contact No.</th>
                                                <th>Email ID</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getSupportList?.data?.result?.length > 0 ?
                                                getSupportList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.supportNo}</td>
                                                            <td>{`${item?.createdAt?.split("T")?.at(0)} , ${formatDateWithAmPm(item?.createdAt)}`}</td>
                                                            <td>{`${item?.firstName}`}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.requestStatus}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="NewActions">
                                                                        <a
                                                                            className="Pink"
                                                                            href="javascript:void(0);"
                                                                            data-toggle="modal"
                                                                            onClick={() => updateStatusModalHideShow("show", item._id, item?.requestStatus)}
                                                                        >
                                                                            Update Status
                                                                        </a>
                                                                        <Link className="Yellow" to="/support-management/view-details" state={{ item, heading: "Support Management" }}>
                                                                            View All Notes
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}
                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getSupportList?.data?.newRequestTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getSupportList?.data?.newRequestTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey="in-progress">
                                <div className="TableList">
                                    <table style={{ width: "130%" }}>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Enquiry ID</th>
                                                <th>Enquiry Date &amp; Time</th>
                                                <th>Username</th>
                                                <th>Contact No.</th>
                                                <th>Email ID</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getSupportList?.data?.result?.length > 0 ?
                                                getSupportList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.supportNo}</td>
                                                            <td>{`${item?.createdAt?.split("T")?.at(0)} , ${formatDateWithAmPm(item?.createdAt)}`}</td>
                                                            <td>{`${item?.firstName}`}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.requestStatus}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="NewActions">
                                                                        <a
                                                                            className="Pink"
                                                                            href="javascript:void(0);"
                                                                            data-toggle="modal"
                                                                            onClick={() => updateStatusModalHideShow("show", item._id, item?.requestStatus)}
                                                                        >
                                                                            Update Status
                                                                        </a>
                                                                        <Link className="Yellow" to="/support-management/view-details" state={{ item, heading: "Support Management" }}>
                                                                            View All Notes
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}
                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getSupportList?.data?.inProgressTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getSupportList?.data?.inProgressTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey='resolved'>
                                <div className="TableList">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                <th>Enquiry ID</th>
                                                <th>Enquiry Date &amp; Time</th>
                                                <th>Username</th>
                                                <th>Contact No.</th>
                                                <th>Email ID</th>
                                                <th>Status</th>
                                                {/* <th>View All Notes</th>  */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getSupportList?.data?.result?.length > 0 ?
                                                getSupportList?.data?.result?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                            <td>{item?.supportNo}</td>
                                                            <td>{`${item?.createdAt?.split("T")?.at(0)} , ${formatDateWithAmPm(item?.createdAt)}`}</td>
                                                            <td>{`${item?.firstName}`}</td>
                                                            <td>{item?.contact}</td>
                                                            <td>{item?.email}</td>
                                                            <td>{item?.requestStatus}</td>
                                                            <td>
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="NewActions">

                                                                        <Link className="Yellow" to="/support-management/view-details" state={{ item, heading: "Support Management" }}>
                                                                            View All Notes
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                                : !loader ?
                                                    <tr>
                                                        <td colSpan="10">
                                                            <p className='center'>No Data found.</p>
                                                        </td>
                                                    </tr>
                                                    : ""}
                                        </tbody>
                                    </table>
                                    {loader ? <p className="load">
                                        <BeatLoader
                                            loading={loader}
                                            size={10}
                                            color='#fd701e'
                                        />
                                    </p> : ""}
                                </div>
                                <div className="pagination">
                                    <ul>
                                        {getSupportList?.data?.resolvedTotal > 0 && (
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={getSupportList?.data?.resolvedTotal}
                                                pageRangeDisplayed={5}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        )}
                                    </ul>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>

            <Modal
                show={updateStatusModal}
                onHide={() => updateStatusModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a
                            href="javascript:void(0);"
                            className="CloseModal"
                            onClick={() => updateStatusModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3>Status &amp; Notes</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <fieldset disabled="">
                                    <div className="form-group">
                                        <label>Current Status</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            name='currentStatus'
                                            value={currentStatus}
                                            onChange={handleInputChange}
                                            disabled
                                        />
                                        <span className='error'>{errors?.currentStatusError}</span>
                                    </div>
                                </fieldset>
                                <div className="form-group">
                                    <label>Update Status</label>
                                    <select className="form-control" onClick={handleInputChange} name='updatedStatus'>
                                        <option selected={updatedStatus == ""} value="">--select--</option>
                                        {currentStatus == "new request" ?
                                            <option selected={updatedStatus == "in-progress"} value="in-progress">in-progress</option> : ''}
                                        <option selected={updatedStatus == "resolved"} value="resolved">resolved</option>
                                    </select>
                                    <span className='error'>{errors?.updatedStatusError}</span>
                                </div>
                                <div className="form-group">

                                    <input
                                        type="date"
                                        className="form-control"
                                        name='date'
                                        // value={date}
                                        max="2500-01-01"
                                        defaultValue={d.toISOString()?.split("T")?.[0]}
                                        disabled
                                        onChange={handleInputChange}
                                    />
                                    <span className='error'>{errors?.dateError}</span>
                                </div>
                                <div className="form-group">

                                    <textarea
                                        className="form-control"
                                        rows={5}
                                        placeholder="Write here.."
                                        defaultValue={""}
                                        name='message'
                                        value={message}
                                        onChange={handleInputChange}
                                    />
                                    <span className='error'>{errors?.messageError}</span>
                                </div>
                            </div>
                        </div>
                        <div className="Buttons">
                            <button className="Button ml-0" onClick={handleUpdate}>Update &amp; Send Email</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="ModalBox">
                <div
                    id="StatusNotesModal"
                    className="modal fade SmallNewModal"
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">

                            </div>
                        </div>
                    </div>
                </div>
                <div id="UserDelete" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Decline">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Delete</h3>
                                    <p>Are you sure you want to delete this User ?</p>
                                    <h4>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            no
                                        </a>
                                        <a href="javascript:void(0);" data-dismiss="modal">
                                            Yes
                                        </a>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="AddNotes" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Add Notes</h3>
                                    <div className="form-group">
                                        <label>Enter Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Add Notes Here</label>
                                        <textarea
                                            rows={5}
                                            className="form-control"
                                            placeholder="Wtite here.."
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div className="Buttons">
                                        <button className="Button">Create</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="ProjectAdminEdit" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="Category">
                                    <a
                                        href="javascript:void(0);"
                                        className="CloseModal"
                                        data-dismiss="modal"
                                    >
                                        ×
                                    </a>
                                    <h3>Edit Project Admin</h3>
                                    <div className="form-group">
                                        <label>Project Name</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Person</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Contact Number</label>
                                        <input type="text" className="form-control" />
                                        <span className="Verify">Verify </span>
                                    </div>
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Upload Photo of User</label>
                                        <input type="file" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm password</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <button className="Button">Submit </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default SupportMgmt
