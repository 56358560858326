import { Bounce } from "react-toastify";

export function isLoggedIn(userType) {
  let session = getObject(userType) || {};
  console.log("session", session);
  session = Object.keys(session).length && session;
  let accessToken = session || "";
  return accessToken;
}

export function getObject(key) {
  if (window && window.localStorage) {
    return window.localStorage.getItem(key);
  }
}

export function convertTo12Hour(time) {
  let [hours, minutes] = time.split(":").map(Number);
  const period = hours >= 12 ? "pm" : "am";

  hours = hours % 12 || 12; // Convert to 12-hour format
  minutes = String(minutes).padStart(2, "0");

  return `${hours}:${minutes} ${period}`;
}

export const commomObj = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export function getPathName() {
  const path = window.location.href;
  const pathArray = path.split("/");
  const pathname = pathArray[pathArray.length - 1];
  return pathname;
}

export function convertTimeFormat(startDate, endDate) {
  function formatTime(time) {
    let [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
  }

  const startTimeFormatted = formatTime(startDate);
  const endTimeFormatted = formatTime(endDate);

  return `${startTimeFormatted} - ${endTimeFormatted}`;
}



export function formatDateWithAmPm(dateString) {
  // Parse the ISO 8601 date string to a Date object
  const date = new Date(dateString);

  // Get hours, minutes, and seconds
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format minutes and seconds to always be two digits
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;
  const secondsStr = seconds < 10 ? "0" + seconds : seconds;

  // Return the formatted date string with AM/PM
  return `${hours}:${minutesStr} ${ampm}`;
}
