import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPathName } from '../../Utils/util';
import { Modal } from 'react-bootstrap';



const initialState = {
    logOutModal: false,
}

const SideNav = () => {


    const [iState, updateState] = useState(initialState);
    const { logOutModal } = iState;
    const SubAdmin = JSON.parse(window.localStorage.getItem("AstroTechAdminDetail"));
    const subAdminRightAccess = SubAdmin?.subAdminRightAccess;
    const adminType = SubAdmin?.adminType;

    let name = [];

    console.log(SubAdmin, 'SubAdmin');

    if (subAdminRightAccess.length > 0) {
        subAdminRightAccess.map((item) => {
            name.push(item.name)
        })
    }
    const navigate = useNavigate();
    const pathname = getPathName();

    const handleLogout = () => {
        window.localStorage.removeItem('AstroTechAdmin')
        window.localStorage.removeItem('AstroTechAdminDetail')
        toast.success('Sucessfully Logged Out', {
            position: 'top-right',
            autoClose: 1000
        })
        navigate('/')
    }

    console.log({ name })
    const logOutModalHideShow = (type, id) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, logOutModal: true })
        }
    }

    return (
        <div>
            <div className="SidenavBar">
                <div className="Logo">
                    <img src={require('../../Assets/images/Logo.png')} />
                </div>
                <ul>
                    {adminType == 'admin' || name.includes('Dashboard') ?
                        <li className={pathname == "dashboard" ? "active" : ''}>
                            <Link to="/dashboard" state='Dashboard'>
                                <span>
                                    <i className="fa fa-tachometer" />
                                </span>{" "}
                                Dashboard
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('User Management') ?
                        <li className={pathname == "userManagement" || pathname == "basicPersonal" || pathname == "appointments" || pathname == "appointment-history-details" ? "active" : ""}>
                            <Link to="/userManagement" state="User Management">
                                <span>
                                    <i className="fa fa-user" />
                                </span>{" "}
                                User Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('Astrologer Management') || name.includes('Astrologer Management / New Registration') || name.includes('Astrologer Management / Approved Astrologers') ?
                        <li className={pathname == "new%20registration" || pathname == "approved-astrologers"
                            || pathname == "interview"
                            || pathname == "on-hold"
                            || pathname == "rejected"
                            || pathname == "new-astrologer-details"
                            || pathname == "personal-detail"
                            || pathname == "appointment-history"
                            || pathname == "availability-schedule"
                            || pathname == "payment-details"

                            ? "dropdown active" : "dropdown"}>

                            <a
                                href="javascript:void(0)"
                                id="navbardrop"
                                data-toggle="dropdown"
                                className="dropdown-toggle"
                            >
                                <span>
                                    <i className="fa fa-font" />
                                </span>{" "}
                                Astrologer Management
                            </a>
                            <ol className="dropdown-menu">
                                {adminType == 'admin' ||
                                    name.includes('Astrologer Management') &&
                                    name.includes('Astrologer Management / New Registration') ||
                                    name.includes('Astrologer Management / New Registration') ||
                                    name.includes('Astrologer Management') &&
                                    !name.includes('Astrologer Management / New Registration') &&
                                    !name.includes('Astrologer Management / Approved Astrologers') ?
                                    <li className={
                                        pathname == "new%20registration"
                                            || pathname == "interview"
                                            || pathname == "on-hold"
                                            || pathname == "rejected"
                                            || pathname == "new-astrologer-details"

                                            ? "active" : ""}>
                                        <Link to="/astrologer-management/new registration" state="Astrologer Management">New Registration</Link>
                                    </li> : ""}
                                {adminType == 'admin' ||
                                    name.includes('Astrologer Management') &&
                                    name.includes('Astrologer Management / Approved Astrologers') ||
                                    name.includes('Astrologer Management / Approved Astrologers') ||
                                    name.includes('Astrologer Management') &&
                                    !name.includes('Astrologer Management / Approved Astrologers') &&
                                    !name.includes('Astrologer Management / New Registration') ?
                                    <li className={
                                        pathname == "approved-astrologers"
                                            || pathname == "personal-detail"
                                            || pathname == "appointment-history"
                                            || pathname == "availability-schedule"
                                            || pathname == "payment-details"

                                            ? "active" : ""}>
                                        <Link to="/astrologer-management/approved-astrologers" state="Approved Astrologers">Approved Astrologers</Link>
                                    </li> : ""}
                            </ol>
                        </li> : ""}

                    {adminType == 'admin' || name.includes('Appointment Management') ?
                        <li className={pathname == "upcoming" || pathname == "completed" || pathname == "canceled" || pathname == "noshow" || pathname == "upcoming-details" || pathname == "completed-details" || pathname == "reschedule-details" || pathname == "noshow-details" ? "active" : ""}>
                            <Link to="/appointment-management/upcoming" state="Appointment Management">
                                <span>
                                    <i className="fa fa-font" />
                                </span>{" "}
                                Appointment Management
                            </Link>
                        </li> : ""}


                    {adminType == 'admin' || name.includes('Service Management') ?
                        <li className={pathname == "service-management" || pathname == "add-service" || pathname == "service-details" || pathname=='edit-service' ? "active" : ""}>
                            <Link to="/service-management" state="Service Management">
                                <span>
                                    <i className="fa fa-font" />
                                </span>{" "}
                                Service Management
                            </Link>
                        </li> : ""}


                    {adminType == 'admin' || name.includes('Payment Management') || name.includes('Payment Management / Transaction Details') || name.includes('Payment Management / Revenue Details') ?
                        <li className={pathname == "transaction-details" || pathname == "revenue-details"
                            ? "dropdown active" : "dropdown"}>
                            <a
                                href="javascript:void(0)"
                                id="navbardrop"
                                data-toggle="dropdown"
                                className="dropdown-toggle"
                            >
                                <span>
                                    <i className="fa-solid fa-hand-holding-dollar" />
                                </span>{" "}
                                Payment Management
                            </a>
                            <ol className="dropdown-menu">
                                {adminType == 'admin' ||
                                    name.includes('Payment Management') &&
                                    name.includes('Payment Management / Transaction Details') ||
                                    name.includes('Payment Management / Transaction Details') ||
                                    name.includes('Payment Management') &&
                                    !name.includes('Payment Management / Transaction Details') &&
                                    !name.includes('Payment Management / Revenue Details') ?
                                    <li className={pathname == "transaction-details"
                                        ? "active" : ""}>
                                        <Link to="/payment-management/transaction-details" state="Transaction Details">Transaction Details</Link>
                                    </li> : ""}
                                {adminType == 'admin' ||
                                    name.includes('Payment Management') &&
                                    name.includes('Payment Management / Revenue Details') ||
                                    name.includes('Payment Management / Revenue Details') ||
                                    name.includes('Payment Management') &&
                                    !name.includes('Payment Management / Revenue Details') &&
                                    !name.includes('Payment Management / Transaction Details') ?
                                    <li className={pathname == "revenue-details"
                                        ? "active" : ""}>
                                        <Link to="/payment-management/revenue-details" state="Revenue Details">Revenue Details</Link>
                                    </li> : ""}
                            </ol>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('Support Management') ?
                        <li className={pathname == "new%20request" || pathname == "in-progress" || pathname == "resolved" || pathname == "view-details" ? "active" : ""}>
                            <Link to="/support-management/new request" state="Support Management">
                                <span>
                                    <i className="fa-solid fa-phone" />
                                </span>{" "}
                                Support Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('Blogs Management') ?
                        <li className={pathname == "blogs-management" || pathname == "add-blogs" || pathname == "category" ? "active" : ""}>
                            <Link to="/blogs-management" state="Blog Management">
                                <span>
                                    <i className="fa fa-server" />
                                </span>{" "}
                                Blogs Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' ?
                        <li className={pathname == "subadmin-management" || pathname == "add-subadmin" || pathname == "sub-admin-details" || pathname == "edit-subadmin" ? "active" : ""}>
                            <Link to="/subadmin-management" state="Sub Admin Management">
                                <span>
                                    <i className="fa fa-users" />
                                </span>{" "}
                                Sub-Admin Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('Banner Management') ?
                        <li className={pathname == "banner-management" ? "active" : ""}>
                            <Link to="/banner-management" state="Banner Management">
                                <span><i class="fa fa-users"></i></span>
                                Banner Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('Notification Management') ?
                        <li className={pathname == "notifications-management" || pathname == "add-category" || pathname == "notifications-thread" ? "active" : ""}>
                            <Link to="/notifications-management" state="Notification Management">
                                <span>
                                    <i className="fa fa-bell" />
                                </span>{" "}
                                Notification Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('Static Content Management') ?
                        <li className={pathname == "content-management" || pathname == "content-management-edit" ? "active" : ""}>
                            <Link to="/content-management" state="Content Management">
                                <span>
                                    <i className="fa fa-random" />
                                </span>{" "}
                                Static Content Management
                            </Link>
                        </li> : ""}
                    {adminType == 'admin' || name.includes('FAQ Management') ?
                        <li className={pathname == "user" || pathname == "astrologer" ? "active" : ""}>
                            <Link to="/faq-management/user" state="FAQ Management">
                                <span>
                                    <i className="fa fa-random" />
                                </span>{" "}
                                FAQ Management
                            </Link>
                        </li> : ""}

                    {adminType == 'admin' || name.includes('My Account') ?
                        <li className={pathname == "my-account" ? "active" : ""}>
                            <Link to="/my-account" state=" My Account">
                                <span>
                                    <i className="fa fa-user" />
                                </span>{" "}
                                My Account
                            </Link>
                        </li> : ""}
                    <li>
                        <a
                            onClick={() => logOutModalHideShow("show")}
                            data-toggle="modal"
                            data-target="#LogOutModal"
                        >
                            <span>
                                <i className="fa fa-sign-out" />
                            </span>{" "}
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
            <Modal
            
                show={logOutModal}
                onHide={() => logOutModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>

                    <a onClick={() => logOutModalHideShow("hide")} className="CloseModal" data-dismiss="modal">
                        ×
                    </a>
                    <div className="LogOutModalArea">
                        <span>
                            <img src={require("../../Assets/images/logout-icon.png")} />
                        </span>
                        <h5>Log Out</h5>
                        <p>Are you sure you want to log out?</p>
                        <div className="Buttons TwoButtons">
                            <button type="button" className="Button" data-dismiss="modal" onClick={() => logOutModalHideShow("hide")}>
                                No
                            </button>
                            <a type="button" className="Button Red" onClick={handleLogout}>
                                Yes
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SideNav