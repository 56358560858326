import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Nav, Tab } from 'react-bootstrap'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userAppointmentHistory, userBasicDetail } from '../../Redux/Slices/userMgmt'
import { commomObj, convertTo12Hour } from '../../Utils/util'
import { toast } from 'react-toastify'
import { BeatLoader } from 'react-spinners'
import Pagination from 'react-js-pagination'
import moment from 'moment'


const initialState = {
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    bookingStatus: 'upcoming'
}

const UserDetail = () => {
    const { type } = useParams()
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [iState, updateState] = useState(initialState);
    const { search, fromDate, toDate, error, timeFrame, bookingStatus } = iState;
    let { id, heading } = location?.state;
    const [activePage, updateActivePage] = useState(1);
    const [serialNo, updateSerialNo] = useState(10);
    const { getUserBasicDetail, getUserAppointment, loader } = useSelector((state) => state.userMgmt);

    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        let ser = pageNumber * 10;
        updateSerialNo(ser);
        const data = {
            page: pageNumber,
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame,
            id,
            bookingStatus

        }
        dispatch(userAppointmentHistory(data))
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'timeFrame') {
            updateState({ ...iState, [name]: value, fromDate: '', toDate: '', error: '' });
        }
        else if (name == "fromDate" || name == "toDate") {
            updateState({ ...iState, [name]: value, timeFrame: '', error: '' });
        }
        else {
            updateState({ ...iState, [name]: value, error: '' });
        }
    }

    const handleRefresh = () => {
        updateState(initialState);
        updateSerialNo(10);
        dispatch(userAppointmentHistory({ id, bookingStatus }))
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '') {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame,
                id,
                bookingStatus
            }
            dispatch(userAppointmentHistory(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        const data = {
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame,
            id,
            bookingStatus
        }
        if (type == "appointments") {
            dispatch(userAppointmentHistory(data))
                .then(res => {
                    console.log("res", res)
                    if (res?.payload?.status == 401) {
                        toast.error("Invalid Token.Please Login again", commomObj);
                        navigate('/');
                    }
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
        else {
            dispatch(userBasicDetail({ id }))
        }
    }, [timeFrame, type, bookingStatus])

    console.log({ type, getUserAppointment })

    return (
        <div>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <Tab.Container defaultActiveKey={type}>
                        <div className="CommonTabs">
                            <Nav className='nav-tabs'>
                                <Nav.Item >
                                    <Nav.Link eventKey="basicPersonal" as={Link} to='/userDetail/basicPersonal'
                                        state={{ id: id, heading: "User Management" }}
                                    > Basic Personal{" "}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="appointments" as={Link} to='/userDetail/appointments'
                                        state={{ id: id, heading: "User Management" }}
                                    > Appointments History</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </div>
                        <Tab.Content className="tab-content">
                            <Tab.Pane className="tab-pane" eventKey='basicPersonal'>
                                <div className="Small-Wrapper" style={{ width: "83%" }}>
                                    <div className="TitleBox">
                                        <h4 className="Title">Person Details</h4>
                                    </div>
                                    <div className="DetailsArea mb-4" style={{ display: 'block' }}>
                                        <div className="DetailsContent">
                                            <aside>
                                                <p>
                                                    <label>Full Name</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.name}</span>
                                                </p>
                                                <p>
                                                    <label>Contact Number </label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.contact}</span>
                                                </p>
                                                <p>
                                                    <label>Email ID</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.email}</span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>Gender</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.gender}</span>
                                                </p>
                                                <p className="d-flex align-items-start">
                                                    <label>Language Preference</label>
                                                    {getUserBasicDetail?.data?.basicDetails?.language?.length > 0 ?
                                                        getUserBasicDetail?.data?.basicDetails?.language?.map((lan, i) => {
                                                            return (
                                                                <span key={i}>{`${lan?.name} ${i !== getUserBasicDetail?.data?.basicDetails?.language?.length - 1 ? "," : ""}`}</span>
                                                            )
                                                        })

                                                        : ''}
                                                </p>
                                            </aside>
                                        </div>
                                    </div>
                                    <div className="DetailsArea mb-4" style={{ display: 'block' }}>
                                        <div className="DetailsContent">
                                            <aside>
                                                <p>
                                                    <label>DOB :</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.dob}</span>
                                                </p>
                                                <p>
                                                    <label>Time of Birth</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.birthTime ? convertTo12Hour(getUserBasicDetail?.data?.basicDetails?.birthTime) : ''}</span>

                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>Place of Birth</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.city}</span>
                                                </p>
                                            </aside>
                                        </div>
                                    </div>
                                    <div className="DetailsArea" style={{ display: 'block' }}>
                                        <div className="DetailsContent">
                                            <aside>
                                                <p>
                                                    <label>Address Line 1</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.address1}</span>
                                                </p>
                                                <p>
                                                    <label>Address Line 2</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.address2}</span>
                                                </p>
                                                <p>
                                                    <label>Country</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.country}</span>
                                                </p>
                                            </aside>
                                            <aside>
                                                <p>
                                                    <label>State</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.state}</span>
                                                </p>
                                                <p>
                                                    <label>City</label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.city}</span>
                                                </p>
                                                <p>
                                                    <label>Pin Code </label>
                                                    <span>{getUserBasicDetail?.data?.basicDetails?.pincode}</span>
                                                </p>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                                <div className="TitleBox">
                                    <h4 className="Title">Family Member Details</h4>
                                </div>
                                <div className="BuildingArea">
                                    <div className="row">
                                        {getUserBasicDetail?.data?.familyMember?.length > 0 ?
                                            getUserBasicDetail?.data?.familyMember?.map((item, i) => {
                                                return (
                                                    <div className="col-lg-5">
                                                        <div className="BuildingPackage">
                                                            <p>
                                                                <label>Full Name</label>
                                                                <span>{item?.name}</span>
                                                            </p>
                                                            <p>
                                                                <label>DOB</label>
                                                                <span>{item?.dob}</span>
                                                            </p>
                                                            <p>
                                                                <label>Time of Birth</label>
                                                                <span>{convertTo12Hour(item?.birthTime)}</span>

                                                            </p>
                                                            <p>
                                                                <label>Location</label>
                                                                <span>{item?.birthPlace}</span>
                                                            </p>
                                                            <p>
                                                                <label>Relation</label>
                                                                <span>{item?.relation}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : ''}
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane className="tab-pane" eventKey="appointments">
                                <div className="Filter">
                                    <div className="form-group">
                                        <label>Select Appointment History</label>
                                        <select id="AppointmentSelector" className="form-control"
                                            name='bookingStatus'
                                            onClick={handleInputChange}>
                                            <option value="upcoming">Upcoming</option>
                                            <option value="completed">Completed</option>
                                            <option value="canceled">Cancel</option>
                                            <option value="noshow">No Show</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div
                                        className="abc Upcoming"
                                        id="Upcoming"
                                        style={{ display: "block" }}
                                    >
                                        <div className="TitleBox mb-0 justify-content-end">
                                            <a
                                                className="TitleLink"
                                                href="javascript:void(0);"
                                                style={{ marginTop: "-75px" }}
                                            >
                                                <i className="fa fa-download" />
                                                Download Excel
                                            </a>
                                        </div>
                                        <div className="Small-Wrapper">
                                            <div className="FilterArea">
                                                <div className="FilterLeft">
                                                    <div className="form-group">
                                                        <label>Search</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search here.."
                                                            name='search'
                                                            value={search}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>From Date</label>
                                                        <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                                    </div>
                                                    <div className="form-group">
                                                        <label>To Date</label>
                                                        <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" min={fromDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <button className="Button" onClick={handleFilterApply}>Apply</button>

                                                        <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                                            <i className="fa fa-refresh" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="FilterRight">
                                                    <div className="form-group">
                                                        <label>Timeframe</label>
                                                        <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                                            <option value='' selected={timeFrame == ""}>Select </option>
                                                            <option value="Today">Today</option>
                                                            <option value="Week">This Week</option>
                                                            <option value="Month">This Month</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className='error'>{error}</span>
                                        </div>
                                        <div className="TableList">
                                            <table style={{ width: "120%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sr. No</th>
                                                        <th>Appointment ID</th>
                                                        <th>Astrologer Name</th>
                                                        <th>Service Name</th>
                                                        <th>Type of Call</th>
                                                        <th>
                                                            Duration of <br /> Call (in min)
                                                        </th>
                                                        <th>Date &amp; Time</th>
                                                        <th>Amount Paid (in Rs.)</th>
                                                        <th>View Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getUserAppointment?.data?.result?.length > 0 ?
                                                        getUserAppointment?.data?.result?.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td> {(i + 1 + serialNo - 10).toString().padStart(3, "0")}</td>
                                                                    <td>{item?.bookingNo}</td>
                                                                    <td>{item?.astrologerData?.fullName}</td>
                                                                    <td>{item?.serviceData?.serviceName}</td>
                                                                    <td>{item?.callType}</td>
                                                                    <td>{item?.duration}</td>
                                                                    <td>
                                                                        {moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")}
                                                                    </td>
                                                                    <td>{item?.price}</td>
                                                                    <td>
                                                                        <div className="Actions">
                                                                            <Link className="Blue" to="/userManagement/appointment-history-details" state="User Management">
                                                                                <i className="fa fa-eye" />
                                                                            </Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : !loader ?
                                                            <tr>
                                                                <td colSpan="10">
                                                                    <p className='center'>No Data found.</p>
                                                                </td>
                                                            </tr>
                                                            : ""}
                                                </tbody>
                                            </table>
                                            {loader ? <p className="load">
                                                <BeatLoader
                                                    loading={loader}
                                                    size={10}
                                                    color='#fd701e'
                                                />
                                            </p> : ""}
                                        </div>
                                        <div className="pagination">
                                            <ul >
                                                {getUserAppointment?.data?.total > 0 && (
                                                    <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={10}
                                                        totalItemsCount={getUserAppointment?.data?.total}
                                                        pageRangeDisplayed={5}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>

        </div>
    )
}

export default UserDetail