import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import S3FileUpload from 'react-s3/lib/ReactS3'
import { toast } from 'react-toastify';
import { commomObj } from "../../Utils/util"
import { useDispatch } from 'react-redux';
import { addService } from "../../Redux/Slices/serviceMgmt"
import { Modal } from 'react-bootstrap';
import { credAndUrl } from '../../Config/config';



const initialState = {
    serviceName: '',
    serviceIcon: '',
    description: '',
    detail: '',
    details: [
        {
            detail: '',
            description: ""
        },],
    serviceOption: [
    ],
    priceFor30Min: '',
    priceFor45Min: '',
    priceFor60Min: '',

    option_name: '',
    option_icon: '',
    service_description: '',
    errors: '',
    errors1: '',
    errors2: '',

    serviceId: '',
    serviceEditModal: false,
    id: '',
}

const AddService = () => {
    const [iState, updateState] = useState(initialState);
    const { serviceName, serviceIcon, description, detail, details, serviceOption, priceFor30Min, priceFor45Min, priceFor60Min, option_name, option_icon, service_description, serviceId, errors, errors1, errors2, serviceEditModal, id } = iState;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { item, heading } = location?.state;


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateState({ ...iState, [name]: value, errors: '', errors1: '', errors2: '' });
    }

    const serviceEditModalHideShow = (type, item, i) => {
        console.log({ item })
        if (type == "hide") {
            updateState({ ...iState, serviceEditModal: false, option_icon: '', service_description: '', option_name: "", errors1: "", id: '' });
        }
        else if (type == "show") {
            updateState({ ...iState, serviceEditModal: true, option_name: item?.optionName, service_description: item?.description, option_icon: item?.icon, errors1: "", id: i })
        }
    }

    const handleAddMoreInput = (e, i) => {
        const { name, value } = e.target;
        const detailsArr = [...details]
        detailsArr[i] = { ...detailsArr[i], [name]: value };
        updateState({ ...iState, details: detailsArr })
    }


    const handleAddMore = () => {
        const newData = {
            detail: '',
            description: ''
        }
        updateState({ ...iState, details: [...details, newData] })
    }
    const handleDelete = (index) => {
        updateState({ ...iState, details: details?.filter((item, i) => i !== index) })
    }

    console.log({ details })
    const handleValidation1 = () => {
        let error = {};
        let formIsValid = true;
        if (!option_name) {
            error.option_nameError = " *Service Option is mandetory";
            formIsValid = false;
        }
        if (!service_description) {
            error.service_descriptionError = " *Service Description is mandetory";
            formIsValid = false;
        }
        if (!option_icon) {
            error.option_iconError = " *Service Icon is mandetory";
            formIsValid = false;
        }
        if (serviceEditModal) {
            updateState({ ...iState, errors2: error });
        }
        else {
            updateState({ ...iState, errors1: error });
        }

        return formIsValid;
    }


    console.log({ errors1 })

    const handleAddOption = () => {
        let formIsValid = handleValidation1();
        if (formIsValid) {
            const data = {
                optionName: option_name,
                icon: option_icon,
                description: service_description,
                isActive: true,
            }
            const isFind = serviceOption?.find(item => item == data);
            if (!isFind && data !== '') {
                updateState({
                    ...iState,
                    serviceOption: [...serviceOption, data],
                    option_name: '',
                    option_icon: '',
                    service_description: '',
                })
            }
        }
    }


    console.log({ serviceOption })


    const config = {
        bucketName: credAndUrl?.BUCKET_NAME,
        dirName: credAndUrl?.DIR_NAME,
        region: credAndUrl?.REGION,
        accessKeyId: credAndUrl?.ACCESS_KEY_ID,
        secretAccessKey: credAndUrl?.SECRET_ACCESS_KEY,
    };

    window.Buffer = window.Buffer || require("buffer").Buffer;

    const onImageHandler = async (e, name) => {
        const file = e.target.files[0];
        console.log("filetype in img", file?.type, file);
        if
            (
            file?.type === "image/jpeg" ||
            file?.type === "image/jpg" ||
            file?.type === "image/png"
        ) {
            if (file.size * 0.000001 <= 5) {
                const data = await S3FileUpload.uploadFile(file, config)
                if (name == "option_icon") {
                    updateState({ ...iState, option_icon: data?.location, errors: '', errors1: '', errors2: '' });
                }
                else if (name == "serviceIcon") {
                    updateState({ ...iState, serviceIcon: data?.location, errors: "" });
                }
                e.target.value = "";

            }
            else {
                toast.error("Image should be below 5MB", commomObj);
            }
        } else {
            toast.error("*Please upload in JPEG,PNG,JPG format Only", commomObj);

        }
    };


    const handleImageClose = () => {
        updateState({ ...iState, serviceIcon: '', option_icon: '' })
    }

    const handleStatus = (i, type, status) => {

        if (type == "delete") {
            updateState({ ...iState, serviceOption: serviceOption.filter((item, index) => index !== i) })
            toast.success("Data deleted successfully", commomObj);
        }
        else {
            const item = [...serviceOption];
            item[i] = { ...item[i], isActive: !status };
            updateState({ ...iState, serviceOption: item });

        }
    }

    const handleEdit = () => {
        let formIsValid = handleValidation1();
        if (formIsValid) {
            const data = {
                optionName: option_name,
                icon: option_icon,
                description: service_description,
            }
            const item = [...serviceOption];
            item[id] = { ...item[id], ...data };
            updateState({ ...iState, serviceOption: item, serviceEditModal: false, errors2: '' });
            toast.success("Service Edit Successfully", commomObj);
        }
    }


    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!serviceName) {
            error.serviceNameError = " *Service Name is mandetory";
            formIsValid = false;
        }
        if (!serviceIcon) {
            error.serviceIconError = " *Service Icon is mandetory";
            formIsValid = false;
        }
        if (!description) {
            error.descriptionError = " *Description is mandetory";
            formIsValid = false;
        }
        if (details?.length == 0) {
            error.detailError = " *Service Details is mandetory";
            formIsValid = false;
        }
        if (!priceFor30Min) {
            error.priceFor30MinError = " *Price field is mandetory";
            formIsValid = false;
        }
        if (!priceFor45Min) {
            error.priceFor45MinError = " *Price field is mandetory";
            formIsValid = false;
        }
        if (!priceFor60Min) {
            error.priceFor60MinError = " *Price field is mandetory";
            formIsValid = false;
        }
        updateState({ ...iState, errors: error });
        return formIsValid;
    }


    const handleSave = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                serviceId,
                serviceName,
                serviceIcon,
                description,
                details,
                serviceOption,
                priceFor30Min,
                priceFor45Min,
                priceFor60Min,
            }
            console.log(data);
            dispatch(addService(data))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        navigate("/service-management")
                    }

                }).catch((err) => {
                    console.log({ err })
                });
        }
    }

    const handleClose = (detail) => {
        updateState({ ...iState, details: details?.filter((item) => item != detail) })
    }


    useEffect(() => {
        if (item) {
            updateState({
                ...iState,
                serviceId: item?._id,
                serviceName: item?.serviceName,
                description: item?.description,
                serviceIcon: item?.serviceIcon,
                details: item?.details,
                serviceOption: item?.serviceOption,
                priceFor30Min: item?.priceFor30Min,
                priceFor45Min: item?.priceFor45Min,
                priceFor60Min: item?.priceFor60Min,
            })
        }
    }, [item])


    console.log({ serviceId })

    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <>
                <div className="WrapperArea">
                    <div className="WrapperBox">
                        <div className="TitleBox">
                            <h4 className="Title"></h4>
                            <div className="BackArrow m-0">
                                <Link to="/service-management">
                                    <i className="fa fa-long-arrow-left" /> Back
                                </Link>
                            </div>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="CommonForm">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Service Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter service name"
                                                name='serviceName'
                                                value={serviceName}
                                                onChange={handleInputChange}
                                            />
                                            <span className='error'>{errors?.serviceNameError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="form-group w-100 mr-3">
                                                <label>Service Description</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="write here.."
                                                    name='description'
                                                    value={description}
                                                    onChange={handleInputChange}
                                                />
                                                <span className='error'>{errors?.descriptionError}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="form-group w-100">
                                                <label>Service Icon</label>
                                                <div class="UploadBox">
                                                    <div class="Upload" style={{ borderRadius: "5px", padding: '10px 10px 10px 15px' }} >
                                                        <span> <i class="fa-solid fa-upload"></i> Upload Service Icon</span>
                                                        <input type="file" onChange={(e) => onImageHandler(e, "serviceIcon")} />
                                                    </div>
                                                </div>

                                                <span className='error'>{errors?.serviceIconError}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {serviceIcon ?
                                            <div className='form-group'>
                                                <label>&nbsp;</label>
                                                <div className="preview-img">
                                                    <span className="cross-btn" onClick={handleImageClose}>×</span>
                                                    <img src={serviceIcon} style={{ borderRadius: "10%" }} />
                                                </div>
                                            </div>
                                            : ""}
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="form-group w-100 mr-3">
                                                <label>Service Details</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    style={{ padding: "8px 10px 8px 15px" }}
                                                    placeholder="Enter Service Details"
                                                    name='detail'
                                                    value={detail}
                                                    onChange={handleInputChange}
                                                />
                                                <span className='error'>{errors?.detailError}</span>
                                            </div>
                                            <div className="form-group">
                                                <button className="Button" onClick={handleAddDetail}>Add</button>
                                            </div>
                                        </div>
                                    </div> */}
                                    {details?.length > 0 ?
                                        details?.map((item, i) => {
                                            return (
                                                <div className="Small-Wrapper" style={{ width: "89%", marginLeft: "16px", marginBottom: i == details?.length - 1 ? "20px" : "0px" }} key={i}>
                                                    <div className="CommonForm">
                                                        <div className="row">
                                                            <div className="col-md-5">
                                                                <div className="form-group">
                                                                    <label>Service Details</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter Service Details"
                                                                        name='detail'
                                                                        value={item?.detail}
                                                                        onChange={(e) => handleAddMoreInput(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="form-group">
                                                                    <label>Service Details Description</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Enter service detail description"
                                                                        name='description'
                                                                        value={item?.description}
                                                                        onChange={(e) => handleAddMoreInput(e, i)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {i !== 0 &&
                                                                <div className="col-md-2">
                                                                    <div className="form-group">
                                                                        <a className="Red">
                                                                            <i className="fa fa-trash" style={{ marginTop: "44px" }} onClick={() => handleDelete(i)} />
                                                                        </a>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                        : ""}

                                    <button className="Button" style={{ marginLeft: "16px" }} onClick={handleAddMore}>Add More+</button>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <ul className="ServiceDetails">
                                                {details?.length > 0 ?
                                                    details?.map((item, i) => {
                                                        return (
                                                            <li key={i}>
                                                                {item}{" "}
                                                                <span style={{ cursor: 'pointer' }}
                                                                >
                                                                    <i className="fa fa-times-circle" aria-hidden="true" onClick={() => handleClose(item)} />
                                                                </span>
                                                            </li>
                                                        )
                                                    })
                                                    : ''}
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="Small-Wrapper">
                            <div className="CommonForm">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Add Service Options</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Add service options"
                                                name='option_name'
                                                value={!serviceEditModal ? option_name : ''}
                                                onChange={handleInputChange}
                                            />
                                            <span className='error'>{errors1?.option_nameError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Add Service Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Add service description"
                                                name='service_description'
                                                value={!serviceEditModal ? service_description : ''}
                                                onChange={handleInputChange}
                                            />
                                            <span className='error'>{errors1?.service_descriptionError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-between align-items-start">
                                            <div className="form-group w-100 mr-3">
                                                <label>Upload Icon</label>
                                                <div class="UploadBox" >
                                                    <div class="Upload" style={{ borderRadius: "5px", padding: '10px 10px 10px 15px' }} >
                                                        <span> <i class="fa-solid fa-upload"></i> Upload Icon</span>
                                                        <input type="file" onChange={(e) => onImageHandler(e, "option_icon")} />
                                                    </div>
                                                </div>
                                                {!serviceEditModal && option_icon ?
                                                    <div className="preview-img" style={{ top: "16px" }}>
                                                        <span className="cross-btn" onClick={handleImageClose}>×</span>
                                                        <img src={option_icon} style={{ borderRadius: "10%" }} />
                                                    </div>
                                                    : ""}
                                                <span className='error'>{errors1?.option_iconError}</span>
                                            </div>
                                            <div className="form-group">
                                                <label>&nbsp;</label>
                                                <button className="Button" onClick={handleAddOption}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Service Option Name</th>
                                        <th>Service Option Description</th>
                                        <th>Icon</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceOption?.length > 0 ?
                                        serviceOption?.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                    <td>{item?.optionName}</td>
                                                    <td>{item?.description}</td>
                                                    <td><span>
                                                        <img src={item?.icon} />
                                                    </span></td>
                                                    <td>
                                                        {item?.isActive ?
                                                            <span className="Green">Active</span> :
                                                            <span className="Red">InActive</span>}

                                                    </td>
                                                    <td>
                                                        <div className="Actions">
                                                            <label className="switch">
                                                                <input type="checkbox" onClick={() => handleStatus(i, "status", item?.isActive)} checked={item?.isActive} />
                                                                <span className="slider" />
                                                            </label>
                                                            <a className="Blue" onClick={() => serviceEditModalHideShow("show", item, i)}>
                                                                <i className="fa fa-pencil" />
                                                            </a>
                                                            <a
                                                                className="Red"
                                                                data-toggle="modal"
                                                                // data-target="#ServiceDelete"
                                                                onClick={() => handleStatus(i, "delete")}

                                                            >
                                                                <i className="fa fa-trash" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        : <tr>
                                            <td colSpan="10">
                                                <p className='center'>No Data found.</p>
                                            </td>
                                        </tr>}

                                </tbody>
                            </table>
                        </div>
                        <div className="Small-Wrapper mt-3">
                            <div className="CommonForm">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Price for 30 min</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter price for 30 min"
                                                name='priceFor30Min'
                                                value={priceFor30Min}
                                                onChange={handleInputChange}
                                            />
                                            <span className='error'>{errors?.priceFor30MinError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Price for 45 min</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter price for 45 min"
                                                name='priceFor45Min'
                                                value={priceFor45Min}
                                                onChange={handleInputChange}
                                            />
                                            <span className='error'>{errors?.priceFor45MinError}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Price for 60 min</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter price for 60 min"
                                                name='priceFor60Min'
                                                value={priceFor60Min}
                                                onChange={handleInputChange}
                                            />
                                            <span className='error'>{errors?.priceFor60MinError}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="Button" onClick={handleSave}>Save</button>
                    </div>
                </div>

            </>

            <Modal
                show={serviceEditModal}
                onHide={() => serviceEditModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a
                            className="CloseModal"
                            onClick={() => serviceEditModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3 style={{ textAlign: "center" }}>Edit Service</h3>
                        <div className="form-group">
                            <label>Enter Service Options</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Link"
                                name='option_name'
                                value={option_name}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors2?.option_nameError}</span>
                        </div>
                        <div className="form-group">
                            <label>Enter Service Option Description</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Link"
                                name='service_description'
                                value={service_description}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors2?.service_descriptionError}</span>
                        </div>
                        <div className="form-group">
                            <label>Upload Icon</label>
                            <div class="UploadBox">
                                <div class="Upload">
                                    <span> <i class="fa-solid fa-upload"></i> Upload Icon</span>
                                    <input type="file" onChange={(e) => onImageHandler(e, "option_icon")} />
                                </div>
                            </div>
                            {option_icon ?
                                <div className="preview-img">
                                    <span className="cross-btn" onClick={handleImageClose}>×</span>
                                    <img src={option_icon} style={{ borderRadius: "10%" }} />
                                </div>
                                : ""}
                            <span className='error'>{errors2?.option_iconError}</span>
                        </div>
                        <div className="Buttons">
                            <button className="Button ml-0" onClick={handleEdit}>Save</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddService
