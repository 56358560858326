import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../Interceptor";
import { isLoggedIn } from "../../Utils/util";

//================================= category list===========================//
export const transactionList = createAsyncThunk(
    "paymentMgmt/transactionList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            // debugger
            const token = isLoggedIn("AstroTechAdmin");
            const response = await axiosInstance.get(`/payment/transaction-list?page=${payload?.page ? payload?.page : ''}&search=${payload?.search ? payload?.search : ''}&startDate=${payload?.startDate ? payload?.startDate : ''}&endDate=${payload?.endDate ? payload?.endDate : ''}&timeFrame=${payload?.timeFrame ? payload?.timeFrame : ''}`, {
                headers: {
                    Authorization: `${token}`
                }
            }
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);




const initialState = {

    getTransactionList: '',
    loader: ''
}

const paymentMgmtSlice = createSlice({
    name: 'payment',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(transactionList.pending, (state) => {
            state.loader = true;
            state.getTransactionList = {}
        })
        builder.addCase(transactionList.fulfilled, (state, action) => {
            state.loader = false;
            state.getTransactionList = action.payload;
        })
        builder.addCase(transactionList.rejected, (state, action) => {
            state.loader = false;
            state.getTransactionList = action.error.message;
        });
    }
})


export default paymentMgmtSlice.reducer;