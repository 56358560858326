import React, { useEffect, useState } from 'react'
import SideNav from '../SideNav/SideNav'
import Header from '../Header/Header'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addBanner, bannerList } from '../../Redux/Slices/bannerMgmt'
import { BeatLoader } from 'react-spinners'
import Pagination from 'react-js-pagination'
import StatusModal from '../Modals/StatusModal'
import S3FileUpload from 'react-s3/lib/ReactS3'
import { toast } from 'react-toastify'
import { commomObj } from '../../Utils/util'
import { useLocation, useNavigate } from 'react-router-dom'
import { credAndUrl } from '../../Config/config'



const initialState = {
    bannerModal: false,
    statusModal: false,
    id: '',
    type: '',
    search: '',
    fromDate: '',
    toDate: '',
    timeFrame: '',
    error: '',
    bannerName: '',
    image: '',
    link: '',
    type1: '',
    bannerId: '',
    title: 'banner',
    errors: '',
    actionResponse: false
}


const BannerMgmt = () => {

    const [iState, updateState] = useState(initialState);
    const { bannerModal, type, search, fromDate, toDate, error, timeFrame, bannerName, image, link, type1, bannerId, errors,actionResponse } = iState;
    const { getBannerList, loader } = useSelector(state => state.bannerMgmt);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const heading = location?.state;
    const [activePage, updateActivePage] = useState(1);

    const bannerModalHideShow = (type) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, bannerModal: true, type1: '' })
        }
    }

    const handleStatusShow = (item, type) => {
        if (type == "banner/delete-banner") {
            updateState({ ...iState, statusModal: true, status: "delete", id: item?._id, type })
        }
    }

    const bannerEditModalHideShow = (type, item) => {
        if (type == "show") {
            updateState({
                ...iState,
                bannerModal: true,
                type1: "Edit",
                bannerName: item?.bannerName,
                image: item?.image,
                link: item?.link,
                bannerId: item?._id,
            })
        }
        else {
            updateState({ ...initialState, type1: "Edit" })
        }

    }


    const handlePageChange = (pageNumber) => {
        updateActivePage(pageNumber);
        const data = {
            page: pageNumber,
        }
        dispatch(bannerList(data))
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'timeFrame') {
            updateState({ ...iState, [name]: value, fromDate: '', toDate: '', error: '' });
        }
        else if (name == "fromDate" || name == "toDate") {
            updateState({ ...iState, [name]: value, timeFrame: '', error: '' });
        }
        else {
            updateState({ ...iState, [name]: value, errors: '' });
        }
    }


    const config = {
        bucketName: credAndUrl?.BUCKET_NAME,
        dirName: credAndUrl?.DIR_NAME,
        region: credAndUrl?.REGION,
        accessKeyId: credAndUrl?.ACCESS_KEY_ID,
        secretAccessKey: credAndUrl?.SECRET_ACCESS_KEY,
    };
    console.log('config', config)
    window.Buffer = window.Buffer || require("buffer").Buffer;

    const onImageHandler = async (e, name) => {
        const file = e.target.files[0];
        console.log(e.target.value, 'value');
        console.log("filetype in img", file?.type, file);
        if
            (
            file?.type === "image/jpeg" ||
            file?.type === "image/jpg" ||
            file?.type === "image/png"
        ) {
            if (file.size * 0.000001 <= 5) {
                const data = await S3FileUpload.uploadFile(file, config)

                updateState({ ...iState, image: data?.location });

                // e.target.value = "";
            }
            else {
                toast.error("Image should be below 5MB", commomObj);
            }
        } else {
            toast.error("*Please upload in JPEG,PNG,JPG format Only", commomObj);

        }
    };

    const handleImageClose = () => {
        updateState({ ...iState, image: '' })
    }

    const handleValidation = () => {
        let error = {};
        let formIsValid = true;

        if (!bannerName) {
            error.bannerNameError = " *Banner Name is mandetory";
            formIsValid = false;
        }
        if (!image) {
            error.imageError = " *Banner Image is mandetory";
            formIsValid = false;
        }
        if (!link) {
            error.linkError = " *Banner Link is mandetory";
            formIsValid = false;
        }

        updateState({ ...iState, errors: error });
        return formIsValid;
    }


    const handleAddBanner = () => {
        let formIsValid = handleValidation();
        if (formIsValid) {
            const data = {
                bannerName,
                image,
                link,
                bannerId,
            }

            dispatch(addBanner(data))
                .then((result) => {
                    console.log({ result })
                    if (result?.payload?.status == 200) {
                        toast.success(result?.payload?.message, commomObj);
                        updateState(initialState);
                        dispatch(bannerList())
                    }

                }).catch((err) => {
                    console.log({ err })
                });
        }

    }
    const handleRefresh = () => {
        updateState(initialState);
        dispatch(bannerList());
    }


    const handleFilterApply = () => {
        if (fromDate == '' && toDate !== '') {
            updateState({ ...iState, error: '*Please select From Date' })
        }
        else if (fromDate !== '' && toDate == '') {
            updateState({ ...iState, error: '*Please select To Date' })
        }
        else if (fromDate == '' && toDate == '' && search?.trim() == '') {
            updateState({ ...iState, error: '*Please select atleast one filter' })
        }
        else {
            const data = {
                search,
                startDate: fromDate,
                endDate: toDate,
                timeFrame
            }
            dispatch(bannerList(data))
                .then(res => {
                    console.log("res", res)
                })
                .catch(err => {
                    console.log("err", err)
                })
        }
    }


    useEffect(() => {
        const data = {
            search,
            startDate: fromDate,
            endDate: toDate,
            timeFrame,
        }
        dispatch(bannerList(data))
        .then((result) => {
            if (result?.payload?.status == 401) {
                toast.error("Invalid Token.Please Login again", commomObj);
                navigate('/');
            }
        }).catch((err) => {

        });
    }, [timeFrame])


    useEffect(() => {
        if (actionResponse) {
            dispatch(bannerList())
                .then(res => {
                    type == "banner/delete-banner" &&  toast.success("Banner deleted Successfully");
                    updateState(initialState);
                })
        }
    }, [actionResponse]);



    console.log({ image })


    return (
        <>
            <Header heading={heading} />
            <SideNav />

            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <a
                            className="TitleLink"
                            href="javascript:void(0);"
                            data-toggle="modal"
                            onClick={() => bannerModalHideShow("show")}
                        // style={{ cursor: getBannerList?.data?.result?.length== 3 ? "not-allowed" : '' }}
                        >
                            {" "}
                            Add Banner{" "}
                        </a>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="FilterArea">
                            <div className="FilterLeft">
                                <div className="form-group">
                                    <label>Search</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here.."
                                        name='search'
                                        value={search}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" name='fromDate' value={fromDate} onChange={handleInputChange} className="form-control" />

                                </div>
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" name='toDate' value={toDate} onChange={handleInputChange} className="form-control" min={fromDate} />
                                </div>
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <button className="Button" onClick={handleFilterApply}>Apply</button>

                                    <button className="Button Cancel" onClick={handleRefresh} style={{ marginLeft: "6px" }}>
                                        <i className="fa fa-refresh" />
                                    </button>
                                </div>
                            </div>
                            <div className="FilterRight">
                                <div className="form-group">
                                    <label>Timeframe</label>
                                    <select className="form-control" name='timeFrame' onClick={handleInputChange}>
                                        <option value='' selected={timeFrame == ""}>Select </option>
                                        <option value="Today">Today</option>
                                        <option value="Week">This Week</option>
                                        <option value="Month">This Month</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <span className='error'>{error}</span>
                    </div>
                    <div className="TableList">
                        <table>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Banner ID</th>
                                    <th>Banner Image</th>
                                    <th>Uploaded On</th>
                                    <th>Last Updated On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {getBannerList?.data?.length > 0 ?
                                    getBannerList?.data?.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                                                <td>{item?.bannerNo}</td>
                                                <td>
                                                    <span>
                                                        <img src={item?.image} />
                                                    </span>
                                                </td>
                                                <td>{item?.createdAt?.split("T")?.at(0)}</td>
                                                <td>{item?.updatedAt?.split("T")?.at(0)}</td>
                                                <td>
                                                    <div className="Actions">
                                                        <a
                                                            className="Green"
                                                            data-toggle="modal"
                                                            onClick={() => bannerEditModalHideShow("show", item)}
                                                        >
                                                            <i className="fa fa-pencil" />
                                                        </a>
                                                        <a
                                                            className="Red"
                                                            data-toggle="modal"
                                                            onClick={() => handleStatusShow(item, "banner/delete-banner")}
                                                        >
                                                            <i className="fa fa-trash" />
                                                        </a>
                                                    </div>                                                </td>

                                            </tr>
                                        )
                                    })
                                    : !loader ?
                                        <tr>
                                            <td colSpan="10">
                                                <p className='center'>No Data found.</p>
                                            </td>
                                        </tr>
                                        : ""}
                            </tbody>
                        </table>
                        {loader ? <p className="load">
                            <BeatLoader
                                loading={loader}
                                size={10}
                                color='#fd701e'
                            />
                        </p> : ""}
                    </div>
                    {/* <div className="pagination">
                        <ul>
                            {getBannerList?.data?.total > 0 && (
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={getBannerList?.data?.total}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            )}
                        </ul>
                    </div> */}
                </div>
            </div>

            {/* Add banner */}
            <Modal
                show={bannerModal}
                onHide={type1 == "Edit" ? () => bannerEditModalHideShow("hide") :
                    () => bannerModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>
                    <div className="Category">
                        <a
                            href="javascript:void(0);"
                            className="CloseModal"
                            data-dismiss="modal"
                            onClick={type1 == "Edit" ? () => bannerEditModalHideShow("hide") : () => bannerModalHideShow("hide")}
                        >
                            ×
                        </a>
                        <h3 style={{ textAlign: 'center' }}>{type1 == "Edit" ? "Edit" : "Add"} Banner</h3>
                        <div className="form-group">
                            <label>Banner Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Banner Name"
                                name='bannerName'
                                value={bannerName}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.bannerNameError}</span>
                        </div>

                        <div className="form-group">
                            <label>Upload Banner Image</label>
                            <div class="UploadBox">
                                <div class="Upload">
                                    <span> <i class="fa-solid fa-upload"></i> Upload Banner</span>
                                    <input type="file" onChange={onImageHandler} />
                                </div>
                            </div>
                            {image ?
                                <div className="preview-img" style={{top:"15px"}}>
                                    <span className="cross-btn" onClick={handleImageClose}>×</span>
                                    <img src={image} style={{ borderRadius: "10%" }} />
                                </div>
                                : ""}
                            <span className='error'>{errors?.imageError}</span>
                        </div>
                        <div className="form-group">
                            <label>Link</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter link here"
                                name='link'
                                value={link}
                                onChange={handleInputChange}
                            />
                            <span className='error'>{errors?.linkError}</span>
                        </div>
                        <div className="Buttons">
                            <button
                                className="Button ml-0"
                                onClick={handleAddBanner}
                            >{type1 == "Edit" ? "Save Changes" : "Add Banner"}

                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <StatusModal iState={iState} updateState={updateState} type={type} />

        </>
    )
}

export default BannerMgmt
