import React from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link, useLocation } from 'react-router-dom';

const ServiceDetail = () => {


    const location = useLocation();
    const { item, heading } = location?.state;


    console.log({ item })
    return (
        <>
            <Header heading={heading} />
            <SideNav />
            <div className="WrapperArea">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <Link to="/service-management" state="Service Management">
                                <i className="fa fa-long-arrow-left" /> Back
                            </Link>
                        </div>
                    </div>
                    <div className="BuildingArea">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="BuildingPackage">
                                    {/* <h4>&nbsp;</h4> */}
                                    <p>
                                        <label>Service Name</label>
                                        <span>{item?.serviceName}</span>
                                    </p>
                                    <p>
                                        <label>Service Description</label>
                                        <span>
                                            {item?.description}
                                        </span>
                                    </p>
                                    <p>
                                        <label>Service Details</label>
                                        <span>
                                            {item?.details?.length > 0 ?
                                                item?.details?.map((item, i) => {
                                                    return (
                                                        <>
                                                            {item?.detail}
                                                            <br />
                                                        </>
                                                    )
                                                })
                                                : ''}
                                            {/* Service Details A <br /> Service Details B <br /> Service
                                            Details C */}
                                        </span>
                                    </p>
                                    <p>
                                        <label>Price for 30 min</label>
                                        <span>Rs {item?.priceFor15Min}</span>
                                    </p>
                                    <p>
                                        <label>Price for 45 min</label>
                                        <span>Rs {item?.priceFor30Min}</span>
                                    </p>
                                    <p>
                                        <label>Price for 60 min</label>
                                        <span>Rs {item?.priceFor45Min}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="BuildingPackage">
                                    <h4>Service Options</h4>
                                    {item?.serviceOption?.length > 0 ?
                                        item?.serviceOption?.map((item, i) => {
                                            return (
                                                <p key={i}>
                                                    <label>Service Option {i+1}</label>
                                                    <span>
                                                        <img src={item?.icon}/>
                                                    </span>
                                                </p>
                                            )
                                        })
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ServiceDetail
