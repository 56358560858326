export const credAndUrl = {
    BASE_URL: '/api/admin/',
    BUCKET_NAME: process.env.REACT_APP_BUCKET_NAME,
    DIR_NAME: process.env.REACT_APP_BUCKET_DIR,
    REGION: process.env.REACT_APP_BUCKET_REGION,
    ACCESS_KEY_ID: process.env.REACT_APP_BUCKET_ACCESSKEYID,
    SECRET_ACCESS_KEY: process.env.REACT_APP_BUCKET_SECRETACCESSKEY,
}



// export const credAndUrl = {
//     BASE_URL: "http://43.204.250.254:5400/api/admin/",
//     BUCKET_NAME: "mobileapplications",
//     DIR_NAME: "photo",
//     REGION: "ap-south-1",
//     ACCESS_KEY_ID: "AKIAQKGGXOAWXVFSQCGZ",
//     SECRET_ACCESS_KEY: "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",
// }    