import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';


const initialState = {
    logOutModal: false,
}


const Header = ({ heading }) => {

    const [iState, updateState] = useState(initialState);
    const { logOutModal } = iState;

    const navigate = useNavigate();



    const logOutModalHideShow = (type, id) => {
        if (type == "hide") {
            updateState(initialState);
        }
        else if (type == "show") {
            updateState({ ...iState, logOutModal: true })
        }
    }

    const handleLogout = () => {
        window.localStorage.removeItem('AstroTechAdmin')
        window.localStorage.removeItem('AstroTechAdminDetail')

        toast.success('Sucessfully Logged Out', {
            position: 'top-right',
            autoClose: 1000
        })
        navigate('/')
    }
    return (
        <div>
            <div className="Header">
                <div className="HeaderBox">
                    <div className="HeaderLeft">
                        <h5>
                            {heading}
                        </h5>
                    </div>
                    <div className="Navigation">
                        <div className="Avater">
                      
                            <a href="javascript:void(0);">
                                <figure>
                                    <img src={require('../../Assets/images/profile.jpg')} />
                                </figure>
                                Admin
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={require('../../Assets/images/profile.jpg')} />
                                    </figure>
                                    <h4>
                                        {" "}
                                        Admin <span>Administrator</span>
                                    </h4>
                                </li>
                                <li>
                                    <a
                                        onClick={() => logOutModalHideShow("show")}
                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                    >
                                        <span>
                                            <i className="fa fa-sign-out" />
                                        </span>{" "}
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
            </div>

            <Modal
                show={logOutModal}
                onHide={() => logOutModalHideShow("hide")}
                className="ModalBox"
            >
                <Modal.Body>

                    <a onClick={() => logOutModalHideShow("hide")} className="CloseModal" data-dismiss="modal">
                        ×
                    </a>
                    <div className="LogOutModalArea">
                        <span>
                            <img src={require("../../Assets/images/logout-icon.png")} />
                        </span>
                        <h5>Log Out</h5>
                        <p>Are you sure you want to log out?</p>
                        <div className="Buttons TwoButtons">
                            <button type="button" className="Button" data-dismiss="modal" onClick={() => logOutModalHideShow("hide")}>
                                No
                            </button>
                            <a type="button" className="Button Red" onClick={handleLogout}>
                                Yes
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Header