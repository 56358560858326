import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SideNav from "../SideNav/SideNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { subAdminList } from "../../Redux/Slices/subAdminMgmt";
import { BeatLoader } from "react-spinners";
import Pagination from "react-js-pagination";
import StatusModal from "../Modals/StatusModal";
import { toast } from "react-toastify";
import { commomObj } from "../../Utils/util";

const initialState = {
  statusModal: false,
  id: "",
  status: "",
  type: "",
  search: "",
  fromDate: "",
  toDate: "",
  timeFrame: "",
  error: "",
  title: "Sub Admin",
  actionResponse: false,
};

const SubAdminMgmt = () => {
  const [iState, updateState] = useState(initialState);
  const {
    id,
    type,
    search,
    fromDate,
    toDate,
    error,
    timeFrame,
    actionResponse,
  } = iState;

  const location = useLocation();
  const navigate = useNavigate();
  const heading = location?.state;
  const dispatch = useDispatch();
  const [activePage, updateActivePage] = useState(1);
  const { getSubAdminList, loader } = useSelector(
    (state) => state.subAdminMgmt
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    updateState({ ...iState, [name]: value, error: "" });
  };

  const handleStatusShow = (item, type) => {
    if (type == "sub-admin/status-update") {
      updateState({
        ...iState,
        statusModal: true,
        status: item?.isActive,
        id: item?._id,
        type,
      });
    } else if (type == "sub-admin/delete-subadmin") {
      updateState({
        ...iState,
        statusModal: true,
        status: "delete",
        id: item?._id,
        type,
      });
    }
  };

  const handleRefresh = () => {
    updateState(initialState);
    dispatch(subAdminList());
  };

  const handleFilterApply = () => {
    if (fromDate == "" && toDate !== "") {
      updateState({ ...iState, error: "*Please select From Date" });
    } else if (fromDate !== "" && toDate == "") {
      updateState({ ...iState, error: "*Please select To Date" });
    } else if (fromDate == "" && toDate == "" && search?.trim() == "") {
      updateState({ ...iState, error: "*Please select atleast one filter" });
    } else {
      const data = {
        search,
        startDate: fromDate,
        endDate: toDate,
      };
      dispatch(subAdminList(data))
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handlePageChange = (pageNumber) => {
    updateActivePage(pageNumber);
    const data = {
      page: pageNumber,
    };
    dispatch(subAdminList(data));
  };

  useEffect(() => {
    const data = {
      search,
      startDate: fromDate,
      endDate: toDate,
      timeFrame,
    };
    dispatch(subAdminList(data))
      .then((res) => {
        console.log("res", res);
        if (res?.payload?.status == 401) {
          toast.error("Invalid Token.Please Login again", commomObj);
          navigate("/");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [timeFrame]);

  useEffect(() => {
    if (actionResponse) {
      dispatch(subAdminList()).then((res) => {
        type == "sub-admin/status-update"
          ? toast.success("Status updated Successfully")
          : toast.success("User deleted Successfully");
        updateState(initialState);
      });
    }
  }, [actionResponse]);

  console.log({ getSubAdminList });

  return (
    <>
      <Header heading={heading} />
      <SideNav />
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title"></h4>
            <div>
              <Link
                className="TitleLink"
                to="/add-subadmin"
                style={{ marginRight: "5px" }}
                state={{ heading: "Add Sub Admin" }}
              >
                Add Sub-Admin
              </Link>
              <a className="TitleLink" href="javascript:void(0);">
                Download CSV
              </a>
            </div>
          </div>
          <div className="Small-Wrapper">
            <div className="FilterArea">
              <div className="FilterLeft">
                <div className="form-group">
                  <label>Search</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here.."
                    name="search"
                    value={search}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="form-group">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="toDate"
                    value={toDate}
                    onChange={handleInputChange}
                    className="form-control"
                    min={fromDate}
                  />
                </div>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <button className="Button" onClick={handleFilterApply}>
                    Apply
                  </button>

                  <button
                    className="Button Cancel"
                    onClick={handleRefresh}
                    style={{ marginLeft: "6px" }}
                  >
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              </div>
              <div className="FilterRight">
                <div className="form-group">
                  <label>Timeframe</label>
                  <select
                    className="form-control"
                    name="timeFrame"
                    onClick={handleInputChange}
                  >
                    <option value="" selected={timeFrame == ""}>
                      Select{" "}
                    </option>
                    <option value="Today">Today</option>
                    <option value="Week">This Week</option>
                    <option value="Month">This Month</option>
                  </select>
                </div>
              </div>
            </div>
            <span className="error">{error}</span>
          </div>
          <div className="TableList">
            <table>
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Sub-Admin ID</th>
                  <th>Sub-Admin Name</th>
                  <th>Number</th>
                  <th>Email ID</th>
                  <th>Registered Date</th>
                  <th>Role</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getSubAdminList?.data?.result?.length > 0 ? (
                  getSubAdminList?.data?.result?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1 < 10 ? `00${i + 1}` : `0${i + 1}`}</td>
                        <td>{item?.subAdminId}</td>
                        <td>{item?.fullName}</td>
                        <td>{item?.contact}</td>
                        <td>{item?.email}</td>
                        <td>{item?.createdAt?.split("T")?.at(0)}</td>
                        <td>{item?.roleName}</td>
                        <td>
                          {item?.isActive ? (
                            <span className="Green">Active</span>
                          ) : (
                            <span className="Red">Inactive</span>
                          )}
                        </td>
                        <td>
                          <div className="Actions">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={item?.isActive}
                                onClick={() =>
                                  handleStatusShow(
                                    item,
                                    "sub-admin/status-update"
                                  )
                                }
                              />
                              <span className="slider" />
                            </label>
                            <Link
                              className="Green"
                              to="/edit-subadmin"
                              state={{ heading: "Edit Sub Admin", item }}
                            >
                              <i className="fa fa-pencil" />
                            </Link>
                            <Link
                              className="Blue"
                              to="/sub-admin-details"
                              state={{ heading: "Sub Admin Details", item }}
                            >
                              <i className="fa fa-eye" />
                            </Link>
                            <a
                              className="Red"
                              data-toggle="modal"
                              onClick={() =>
                                handleStatusShow(
                                  item,
                                  "sub-admin/delete-subadmin"
                                )
                              }
                            >
                              <i className="fa fa-trash" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : !loader ? (
                  <tr>
                    <td colSpan="10">
                      <p className="center">No Data found.</p>
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
            {loader ? (
              <p className="load">
                <BeatLoader loading={loader} size={10} color="#fd701e" />
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="pagination">
            <ul>
              {getSubAdminList?.data?.total > 0 && (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={getSubAdminList?.data?.total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              )}
            </ul>
          </div>
        </div>
      </div>
      <StatusModal iState={iState} updateState={updateState} type={type} />
    </>
  );
};

export default SubAdminMgmt;
